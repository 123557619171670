import React, { useState, useEffect } from 'react';
import './style-pages/usercheckout.css';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import axios from 'axios';
import TopBarFunnel from './components/TopBarFunnel';

import bolt from './assets/bolt_icon.png';
import check from './assets/check-circle.png';

export default function UserCheckout() {
    const navigate = useNavigate();
    const [isYearly, setIsYearly] = useState(false);
    const auth = getAuth();
    
    const plans_data = [
        {
            "name": 'Social Plan',
            "monthly_price": "$14.99",
            "yearly_price": "$99.99",
            "yearly_monthly_price": "$8.35",
            "deal_value": "Save $80!",
            "description": "For premium social features to enhance your betting experience.",
            "outline_enhancement": "",
            "button_enhancement": "",
            "features": [
                "Private Member Feeds",
                "Influencer Betting Groups",
                "Golden Crown Verification",
                "Attach Parlays to Chats",
                "Access to Community Data",
            ],
        },
        {
            "name": 'Basic Plan',
            "monthly_price": "$74.99",
            "yearly_price": "$699.99",
            "yearly_monthly_price": "$58.35",
            "deal_value": "Save $200!",
            "description": "Get access to the ProPicks betting analytics tools and premium social features.",
            "outline_enhancement": "enhanced",
            "button_enhancement": "button-enhanced",
            "features": [
                "Arbitrage Betting Early Access",
                "Positive EV Betting Early Access",
                "Bet Scanner Analytics Tool",
                "Betting Calculators",
                "Plus Social Plan Features"
            ]
        },
        {
            "name": 'Pro Plan',
            "monthly_price": "$124.99",
            "yearly_price": "$999.99",
            "yearly_monthly_price": "$83.33",
            "deal_value": "Save $500!",
            "description": "Unlock access to the best betting tools to boost your profits and drive more wins!",
            "outline_enhancement": "",
            "button_enhancement": "",
            "features": [
                "Full Propicks Feature Access",
                "Live Data Updates Early Access",
                "New Feature Voting Access",
                "AI Picks Early Access",
                "Plus all Basic Plan Features",
            ],
        }
    ]

    const fetchUserProfile = async (gid) => {
        const profile_response = await axios.post("https://propicks-data-api.com/v1/get-user", {
            gid: gid,
        })

        if(profile_response.status == 200){
            sessionStorage.setItem('user-session-data', JSON.stringify(profile_response.data.user));
        } else {
            console.log({ err: "Call not succesful "})
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            fetchUserProfile(user.uid);
        })

        return () => unsubscribe();
    }, [])

    return(
        <div className='user-checkout-content' >
            <TopBarFunnel step={5} />
            <div className="plans-main-container" >
                <div className='plans-header-container' >
                    <p className='plans-header' >Choose a Plan</p>
                    <div 
                        className={`toggle-container `}
                        onClick={() => setIsYearly(!isYearly)}
                    >
                        <span className="toggle-label left">Monthly</span>
                        <span className="toggle-label right">Yearly </span>   
                        <div className={`toggle-button ${isYearly ? "on" : ""}`}></div>
                    </div>
                </div>
                <div className='plans-frames-container' >
                    { isYearly ? (
                        <>
                            {
                                plans_data.map((plan) => {
                                    return(
                                        <div className={`plans-frame ${plan.outline_enhancement}`} >
                                            <div className='plan-title-container'>
                                                <img className='plan-logo' src={bolt} height="32" width="32" />
                                                <p className='plan-title' >{plan.name}</p>
                                            </div>
                                            <p className='plan-description' >{plan.description}</p>
                                            <p className='plan-price'>{plan.yearly_price}<span>/year </span><span>- {plan.deal_value}</span></p>
                                            <div className={`plan-purchase-button ${plan.button_enhancement}`} >
                                                <p>Purchase Plan</p>
                                            </div>
                                            <div className='plan-features-container' >
                                                {
                                                    plan.features.map((feature) => {
                                                        return(
                                                            <div className='feature-row' >
                                                                <img src={check} height="14" width="14" />
                                                                <p>{feature}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                    ) : (
                        <>
                            {
                                plans_data.map((plan) => {
                                    return(
                                        <div className={`plans-frame ${plan.outline_enhancement}`} >
                                            <div className='plan-title-container'>
                                                <img className='plan-logo' src={bolt} height="32" width="32" />
                                                <p className='plan-title' >{plan.name}</p>
                                            </div>
                                            <p className='plan-description' >{plan.description}</p>
                                            <p className='plan-price'>{plan.monthly_price}<span>/month </span></p>
                                            <div className={`plan-purchase-button ${plan.button_enhancement}`} >
                                                <p>Purchase Plan</p>
                                            </div>
                                            <div className='plan-features-container' >
                                                {
                                                    plan.features.map((feature) => {
                                                        return(
                                                            <div className='feature-row' >
                                                                <img src={check} height="14" width="14" />
                                                                <p>{feature}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )}
                </div>
                <div className='skip-container' >
                    <div className='skip-button' onClick={() => {navigate("/")}}>
                        <p>Skip for Now</p>
                    </div>
                </div>
            </div>
        </div>
    )
}