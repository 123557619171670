import React from 'react';
import './style-pages/notifications.css';
import TopBar from './components/TopBar';

export default function Notifications() {
    return(
        <div className='notifications-content' >
            <TopBar />
            <div className='notifications-page-container' >
                <p className='notifications-title' >New Notifications</p>
            </div>
        </div>
    )
}