// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-funnel-content {
    height: 100vh;
    width: 100%;
    background-color: var(--brand-shade-500);
}

.user-funnel-container {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/user-flow-pages/style-pages/userfunnel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,wCAAwC;AAC5C;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".user-funnel-content {\n    height: 100vh;\n    width: 100%;\n    background-color: var(--brand-shade-500);\n}\n\n.user-funnel-container {\n    width: 100%;\n    height: calc(100% - 60px);\n    display: flex;\n    justify-content: center;\n    flex-direction: row;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
