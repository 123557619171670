import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import './style-pages/dashboard.css';
import Sidebar from './components/Sidebar';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import heart_white from './assets/heart_white.svg';
import heart_red from './assets/heart_red.svg';
import reply from './assets/reply.svg';

export default function MainDashboard() {
    const navigate = useNavigate();
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const [disabled, setDisabled] = useState(true);
    const [authenticated, setAuthentication] = useState(false);
    const [user, setUser] = useState({});
    const [chats, setChats] = useState([]);
    const [dataExists, setDataExists] = useState(false);
    const [userMessage, setUserMessage] = useState();
    const [placeHolder, setPlaceHolder] = useState("Sign In to Post!");
    const [userTeams, setUserTeams] = useState([]);
    const [arbCount, setArbCount] = useState(0);
    const [pevCount, setPevCount] = useState(0);

    const loadDashboard = useCallback(async (gid) => {
      try{
        const response = await axios.post("https://propicks-data-api.com/load-dashboard", {
          gid: gid
        })

        console.log(response.data)

        let reverse_chats = response.data.chats.reverse();
        
        setChats(reverse_chats);
        setArbCount(response.data.counts.arb_count.count);
        setPevCount(response.data.counts.pev_count.count);
        setDataExists(true);

        if (session != null) {
          setUser(JSON.parse(session));
          setUserTeams(response.data.teams);
        } else {
          if(response.data.user != undefined || response.data.user != null){
            setUser(response.data.user);
            sessionStorage.setItem('accountData', JSON.stringify(response.data.user));
          }
          setUserTeams(response.data.teams);
        }
      } catch (err) {
        console.log("An error has occurred when loading dashbaord.")
        console.log({ message: err.message })
      }
    }, [])

    const getChatInfo = useCallback(async () => {
      try {
        const result = await axios.get("https://propicks-data-api.com/recent-posts");
        console.log(result.data.chats);
        let reverse_chats = result.data.chats.reverse();
        setChats(reverse_chats);
        setDataExists(true);
      } catch (err) {
        console.log({ message: err.message });
      }
    }, []);
    
    const handleInputChange = (event) => {
      setUserMessage(event.target.value);
    };

    const postMessage = async () => {
      const date = new Date();
      const uuid = uuidv4();

      const general_id = "00000000-0000-0000-0000-000000000000";
      const general_name = "General";

      const chat = {
          message_id: uuid,
          user_name: user.name,
          user_gid: user.gid,
          message_data: userMessage,
          post_date: date,
          chat_stream_id: general_id,
          forum_name: general_name,
      }

      const chat_array = [uuid, user.name, user.gid, userMessage, date, general_id, null, general_name]

      // Combine these two calls into one, client should not make two api calls in a single method
      await axios.post("https://propicks-social-api.com/post-team-feed-message", {
          chat: chat,
      })
          .then((response) => {
              console.log(response);
              console.log(chat_array);
              setChats(chats => [...chats, chat_array]);
              setUserMessage('')
          })
          .catch((err) => {
              console.log({"message": err.message});
          })

      await axios.post("https://propicks-data-api.com/add-user-post", {
          "user_gid": user.gid,
          "message_id": uuid,
      })
          .catch((err) => {
              console.log({ "message": err.message });
          })

      forceUpdate();
    }

    const openUserProfile = (user_gid) => {
      const newParams = createSearchParams({
          gid: user_gid
      });
      
      navigate({
          pathname: '/userProfile',
          search: `?${newParams.toString()}`
        });
    }

    const getUserInfo = useCallback(async (gid) => {
      if (session != null) {
        setUser(JSON.parse(session));
      } else {
        console.log("session is null on main")
        await axios.post("https://propicks-data-api.com/get-user", {
            gid: gid,
          })
            .then((response) => {
              setUser(response.data.postgres_response);
              sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
            })
            .catch((err) => {
              console.log({ err: err });
            })
      }
    });

    const respondToMessage = (user_name) => {
      setUserMessage(`@${user_name} `)
    }

    const likeMessage = (message_id) => {
      console.log(`User: ${user.gid} liked message: ${message_id}`)
    }

    const openTeamFeed = (chat_id) => {
      const newParams = createSearchParams({
          id: chat_id
      });
      
      navigate({
          pathname: '/teamchat',
          search: `?${newParams.toString()}`
        });
    }

    const openChat = (chat_id) => {
      const newParams = createSearchParams({
          id: chat_id
      });
      
      navigate({
          pathname: '/chat',
          search: `?${newParams.toString()}`
        });
    }

    useEffect(() => {
      const fetchData = async () => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            setAuthentication(true);
            loadDashboard(user.uid);
            setDisabled(false);
            setPlaceHolder("Say something!")
          } else {
            console.log("no user :(");
            loadDashboard();
          }
        });
      };
    
      fetchData();
    }, []);

    if(!dataExists && !authenticated){
      return (
        <div className='dashboard'>
          <Sidebar />
          <div className='main-dashboard-content'>
            <p> loading... </p>
          </div>
        </div>
      )
    }

    if(authenticated && dataExists){
        return (
            <div className="dashboard">
              <Sidebar />
              <main className="main-dashboard-content">
                <div className="modules">
                  <div className="module right">
                  <div className="chat-stream-module">
                          <div className='main-chat-stream'>
                            {chats.length > 0 ? (<>
                              {
                                chats.map((chat) => {
                                  return(
                                    <div key={chat.message_id} className='dashboard-team-feed-message'>
                                        <p className='chat-user' ><a style={{cursor: 'pointer'}} onClick={() => {openUserProfile(chat.user_gid)}}>{chat.user_name}</a> <span className='user-forum-span'> in </span><span className='forum-span' onClick={() => {openTeamFeed(chat.chat_stream_id)}}> {chat.forum_name}</span></p>
                                        <p className='chat-content' onClick={() => {openChat(chat.message_id)}} >{chat.message_data}</p>
                                        <div className='button-div-chats' >
                                          <button className="chat-button-like" disabled={disabled} onClick={() => {likeMessage(chat.message_id)}} ><img src={heart_white} height="20" width="20" /></button>
                                          <button className="chat-button-reply" disabled={disabled} onClick={() => {respondToMessage(chat.user_name)}} ><img src={reply} height="20" width="20" /></button>
                                        </div>
                                    </div>
                                  )
                                })
                              }
                            </>):(<>
                              <p>No Chats loaded</p>
                            </>)}
                          </div>
                          <div className='dashboard-input-group'>
                            <input className='dashboard-chat-input-field' type='text' placeholder={placeHolder} disabled={disabled} value={userMessage} onChange={handleInputChange} ></input>
                            <button className='dashboard-input-button' disabled={disabled} onClick={() => {postMessage()}}> Post </button>
                          </div>
                        </div>
                  </div>
                  <div className="module left">
                    <div className="account-module">
                      <h2 className='dashboard-header'>Welcome {user.name}</h2>
                      <h4 className="dashboard-user-section-header">Your Teams:</h4>
                      {
                        userTeams.map((team) => {
                          return(
                            <div className='team-name-display-div' onClick={() => {openTeamFeed(team.chat_stream_id)}}>
                              <p>{team.team_name}</p>
                            </div>
                          )
                        })
                      }
                      <h4 className="dashboard-user-section-header">Your Bets:</h4>
                      <p>PEV: {pevCount}</p>
                      <p>Arbitrage: {arbCount}</p>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          );
    }

    if(!authenticated && dataExists){
      return(
        <div className="dashboard">
            <Sidebar />
            <main className="main-dashboard-content">
                <div className="modules">
                    <div className="module right">
                        <div className="chat-stream-module">
                          <div className='main-chat-stream'>
                            {chats.length > 0 ? (<>
                              {
                                chats.map((chat) => {
                                  return(
                                    <div key={chat.message_id} className='dashboard-team-feed-message'>
                                      <p className='chat-user' ><a style={{cursor: 'pointer'}} onClick={() => {openUserProfile(chat.user_gid)}}>{chat.user_name}</a> <span className='user-forum-span'> in </span><span className='forum-span' onClick={() => {openTeamFeed(chat.chat_stream_id)}}> {chat.forum_name}</span></p>                                        <p className='chat-content' onClick={() => {openChat(chat.message_id)}} >{chat.message_data}</p>
                                      <div className='button-div-chats' >
                                        <button className="chat-button-like" disabled={disabled} onClick={() => {likeMessage(chat.message_id)}} ><img src={heart_white} height="20" width="20" /></button>
                                        <button className="chat-button-reply" disabled={disabled} onClick={() => {respondToMessage(chat.user_name)}} ><img src={reply} height="20" width="20" /></button>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </>):(<><p>No Chats loaded</p></>)}
                          </div>
                          <div className='dashboard-input-group'>
                            <input className='dashboard-chat-input-field' type='text' placeholder={placeHolder} disabled={disabled} value={userMessage} onChange={handleInputChange} ></input>
                            <button className='dashboard-input-button' disabled={disabled} onClick={() => {postMessage()}}> Post </button>
                          </div>
                        </div>
                    </div>
                    <div className="module left">
                        <div className='welcome-module'>
                            <h2 className='dashboard-header'>Welcome to Propicks</h2>
                            <button className='signin-dash-button' onClick={() => {navigate('/signin')}}>Sign In</button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
      )
    }
}

/*
Unused Code: 

<div className="news-module">
    <h3 className='news-header'>Sports News</h3>
</div>
<div className='placeholder-module-1'>
  <h3 className='placeholder-header-1'>New module coming soon...</h3>
</div>
<div className='placeholder-module-2'>
  <h3 className='placeholder-header-2'>New module coming soon...</h3>
</div>

 <img src={heart} style={{height: 20, marginTop: 2.5,}}/>
*/