import React from 'react';
import './style-pages/testscanner.css';
import TopBar from './components/TopBar';

export default function TestScanner() {
    return(
        <div className='test-scanner-content' >
            <TopBar />
        </div>
    )
}