import React from 'react';
import TopBar from './components/TopBar';
import './style-pages/sportsbooks.css';
import { sportsbook_data } from './data/bookdata';

export default function Sportsbooks() {
    return (
        <div className='sportsbooks-content' >
            <TopBar />
            <p className='offering-title' >
                Sportsbook Offerings
            </p>
            <div className='sportsbooks-offerings' >
                {/* This is where the offerings for the sports books will be mapped */}
                {
                    sportsbook_data.map((book) => {
                        return(
                            <>
                                <div className='book-offer' >
                                    <div className='book-offer-top'>
                                        <img src={book.book_logo} height="28" width="28" />
                                        <p>{book.book_name}</p>
                                    </div>
                                    <p className='offer-description'>{book.book_offer}</p>
                                    <div className='offer-button' >
                                        <p>Claim Offer</p>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}