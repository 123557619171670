import React from 'react';
import './style-pages/privacy.css';
import TopBar from './components/TopBar';

export default function Privacy() {
    return(
        <div className='privacy-content'>
            <TopBar />
        </div>
    )
}