import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style-pages/topbarfunnel.css';

import logo from '../assets/logo2.png';

export default function TopBarFunnel({ step }) {
    const navigate = useNavigate();
    const funnel_step = step;

    console.log(funnel_step)

    return(
        <div className='topbar-funnel-content' >
            <div className='logo-container' onClick={() => navigate("/")}>
                <img src={logo} height="24" width="24" />
                <h4 className='propicks-title'>ProPicks</h4>
            </div>
            <div className='step-container' >
                <div className='step-visual-container' >
                    <div className={`step-visual ${step >= 1 ? "complete" : ""}`} ></div>
                    <div className={`step-visual ${step >= 2 ? "complete" : ""}`} ></div>
                    <div className={`step-visual ${step >= 3 ? "complete" : ""}`} ></div>
                    <div className={`step-visual ${step >= 4 ? "complete" : ""}`} ></div>
                    <div className={`step-visual ${step >= 5 ? "complete" : ""}`} ></div>
                </div>
                <div className='step-text-container' >
                    <p>Step {step} of 5</p>
                </div>
            </div>
        </div>
    )
}