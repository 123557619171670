import React, { useState, useEffect } from "react";
import Sidebar from "./components/Sidebar";
import { useNavigate, createSearchParams } from "react-router-dom";
import "./style-pages/generalsocial.css";
import axios from 'axios';

export default function GeneralSocial() {
    const navigate = useNavigate();
    const [teamFeeds, setTeamFeeds] = useState({});
    const [dataExists, setExists] = useState(false);

    const getTeamFeeds = async () => {
        await axios.get("https://propicks-social-api.com/get-all-team-feeds")
            .then((response) => {
                console.log(response.data.data);
                const shortTeamFeeds = []
                let i = 0;
                while (i < 5) {
                    shortTeamFeeds.push(response.data.data[i])
                    i = i + 1;
                }
                setTeamFeeds(shortTeamFeeds);
                setExists(true);
            })
            .catch((err) => {
                console.log({"message": err.message})
            })
    }

    const openTeamFeed = (chat_id) => {
        const newParams = createSearchParams({
            id: chat_id
        });
        
        navigate({
            pathname: '/teamchat',
            search: `?${newParams.toString()}`
          });
    }

    useEffect(() => {
        console.log("Getting teams...")
        getTeamFeeds()
    }, [])

    if(!dataExists){
        return(
            <div className="general-content">
                <Sidebar />
                <div className="main-content" >
                    <p> Loading... </p>
                </div>
            </div>
        )
    }

    return (
        <div className="general-content">
            <Sidebar />
            <main className='main-content'>
                <h2>Explore</h2>
                <div className="explore-top-section" >
                    <div className="general-forum">
                        <h3>
                            General Chat
                        </h3>
                    </div>
                    <div className="daily-picks" >
                        <h3>
                            Daily Picks
                        </h3>
                    </div>
                </div>
                <h3>Team Chats: </h3>
                <div className="explore-team-chats">
                    {
                        teamFeeds.map((teamFeed) => {
                            return (
                                <div key={teamFeed[1]} className="team-feed-module" onClick={() => {openTeamFeed(teamFeed[5])}}>
                                    <div className="game-title-next">
                                        <h4 className="team-feed-module-title">
                                            {teamFeed[0]}
                                        </h4>
                                        <p>
                                            Next game: {teamFeed[2]}
                                        </p>
                                    </div>
                                    <div className="enter-name">
                                        <h4>
                                            {"Enter >"}
                                        </h4>
                                    </div>
                                </div>
                            )
                    })}
                </div>
            </main>
        </div>
    )
}