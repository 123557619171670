import React, { useState } from 'react';
import './style-pages/userfunnel1.css';

export default function UserFunnel1({ onNext }) {
    const [rangeSelected, setRangeSelected] = useState(0);

    return(
        <div className='age-content' >
            <p className='age-range-title' >What's your Age Range?</p>
            <div className='age-ranges-container' >
                <div className={`age-range ${rangeSelected === 1 ? "selected" : ""}`} onClick={() => {
                    if(rangeSelected == 1)setRangeSelected(0);
                    else setRangeSelected(1);
                }}>
                    <p>Under 18</p>
                </div>
                <div className={`age-range ${rangeSelected === 2 ? "selected" : ""}`} onClick={() => {
                    if(rangeSelected == 2)setRangeSelected(0);
                    else setRangeSelected(2);
                }}>
                    <p>18 - 35</p>
                </div>
                <div className={`age-range ${rangeSelected === 3 ? "selected" : ""}`} onClick={() => {
                    if(rangeSelected == 3)setRangeSelected(0);
                    else setRangeSelected(3);
                }}>
                    <p>35 - 55</p>
                </div>
                <div className={`age-range ${rangeSelected === 4 ? "selected" : ""}`} onClick={() => {
                    if(rangeSelected == 4)setRangeSelected(0);
                    else setRangeSelected(4);
                }}>
                    <p>55 and Over</p>
                </div>
            </div>
            <div className='age-continue-button' onClick={() => onNext({age: rangeSelected })}>
                <p>Continue</p>
            </div>
        </div>
    )
}