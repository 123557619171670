// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar-content-signup {
    width: calc(100% - 20%);
    height: 24px;
    padding: 14px 10% 18px 10%;
    top: 0px;
    left: 0px;
    background-color: var(--brand-shade-600);
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: 0;
}`, "",{"version":3,"sources":["webpack://./src/user-flow-pages/components/style-pages/topbar.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,0BAA0B;IAC1B,QAAQ;IACR,SAAS;IACT,wCAAwC;IACxC,gCAAgC;IAChC,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,MAAM;AACV","sourcesContent":[".topbar-content-signup {\n    width: calc(100% - 20%);\n    height: 24px;\n    padding: 14px 10% 18px 10%;\n    top: 0px;\n    left: 0px;\n    background-color: var(--brand-shade-600);\n    font-family: 'Inter', sans-serif;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    top: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
