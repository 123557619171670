import React, { useState, useEffect } from 'react';
import './style-pages/testpev.css';
import TopBar from './components/TopBar';
import { onAuthStateChanged, getAuth } from 'firebase/auth';

import live from './assets/live.png';
import banner from './assets/premium_banner.png';
import filter from './assets/filter.png';
import refresh from './assets/refresh.png';
import columns from './assets/columns.png';

import { sportsbook_data } from './data/bookdata';

export default function TestPositiveEV() {
    const [isLive, setIsLive] = useState(false);
    const [dataBlurred, setDataBlurred] = useState(false);
    const [menuShown, setMenuShown] = useState(false);
    const [value, setValue] = useState(0);
    const [blurShown, setBlurShown] = useState(false);
    const [columnMenuShown, setColumnMenuShown] = useState(false);

    const auth = getAuth();

    const row_data = [
        [
            "+37%", "Ohio State vs Michigan", "Moneyline", "Ohio State to Win", "-305", "Fanduel"
        ],
        [
            "+37%", "Ohio State vs Michigan", "Moneyline", "Ohio State to Win", "-305", "Fanduel"
        ],
        [
            "+37%", "Ohio State vs Michigan", "Moneyline", "Ohio State to Win", "-305", "Fanduel"
        ],
        [
            "+37%", "Ohio State vs Michigan", "Moneyline", "Ohio State to Win", "-305", "Fanduel"
        ],
        [
            "+37%", "Ohio State vs Michigan", "Moneyline", "Ohio State to Win", "-305", "Fanduel"
        ],
        [
            "+37%", "Ohio State vs Michigan", "Moneyline", "Ohio State to Win", "-305", "Fanduel"
        ],
        [
            "+37%", "Ohio State vs Michigan", "Moneyline", "Ohio State to Win", "-305", "Fanduel"
        ],
        [
            "+37%", "Ohio State vs Michigan", "Moneyline", "Ohio State to Win", "-305", "Fanduel"
        ],
        [
            "+37%", "Ohio State vs Michigan", "Moneyline", "Ohio State to Win", "-305", "Fanduel"
        ],
        [
            "+37%", "Ohio State vs Michigan", "Moneyline", "Ohio State to Win", "-305", "Fanduel"
        ],
        [
            "+37%", "Ohio State vs Michigan", "Moneyline", "Ohio State to Win", "-305", "Fanduel"
        ],
        [
            "+37%", "Ohio State vs Michigan", "Moneyline", "Ohio State to Win", "-305", "Fanduel"
        ],
        [
            "+37%", "Ohio State vs Michigan", "Moneyline", "Ohio State to Win", "-305", "Fanduel"
        ],
    ]

    const sports = [
        {
            "name": "NFL Football",
            "code": "nfl",
        },
        {
            "name": "College Football",
            "code": "ncaafootball",
        },
        {
            "name": "NBA Basketball",
            "code": "nba",
        },
        {
            "name": "College Basketball",
            "code": "ncaabasketball",
        },
        {
            "name": "MLB Baseball",
            "code": "mlb",
        },
    ]

    const column_names = [
        "Arb %",
        "Event Details",
        "Markets",
        "Bet Direction",
        "Odds",
        "Sports Book",
    ]

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(!user){
                console.log("no user detected")
                setDataBlurred(!dataBlurred);
            } else {
                console.log("User is: ", user)
            }
        })
    }, [])

    return(
        <div className={`test-positiveev-content`} >
            <div className={`blur-group`}>
                <TopBar />
                <div className={`test-positiveev-main`}>
                    <div className='go-premium-banner'>
                        {
                            dataBlurred ? (<img src={banner} />) : (<></>)
                        }
                    </div>
                    <div className='data-table-options'>
                        <div className='left-side-options'>
                            <p className='data-title'>
                                Positive EV Bets
                            </p>
                            <div 
                                className={`data-toggle-container `}
                                onClick={() => setIsLive(!isLive)}
                            >
                                <span className="data-toggle-label data-left">Upcoming</span>
                                <span className="data-toggle-label data-right">Live<img src={live} height="14" width="14" /> </span>   
                                <div className={`data-toggle-button ${isLive ? "data-on" : ""}`}></div>
                            </div>
                        </div>
                        <div className='right-side-options'>
                            <div className='options-button' >
                                <img src={filter} height="30" width="30" alt="Filter Chart" style={{marginBottom: -4}} onClick={() => {
                                    setBlurShown(true);
                                    setMenuShown(true);
                                }}/>
                            </div>
                            <div className='options-button' >
                                <img src={refresh} height="30" width="30" alt="Refresh Chart" style={{marginBottom: -4}} />
                            </div>
                            <div className='options-button' >
                                <img src={columns} height="30" width="30" alt="Toggle Columns" style={{marginBottom: -4}} onClick={() => {
                                    setBlurShown(true);
                                    setColumnMenuShown(true);
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='data-table-container'>
                        <table className='data-table-pev'>
                            <thead>
                                <tr>
                                    <th className='left-corner' >+EV</th>
                                    <th className='mid-header' >Event Details</th>
                                    <th className='mid-header' >Markets</th>
                                    <th className='mid-header' >Bet Direction</th>
                                    <th className='mid-header' >Odds</th>
                                    <th className='right-corner'>Sports Book</th>
                                </tr>
                            </thead>
                            {
                                row_data.map((row) => {
                                    return(
                                        <tbody className={dataBlurred ? "blur" : ""}>
                                            <tr>
                                                <td className='left-edge' ><span className='percentage'>{row[0]}</span></td>
                                                <td className='middle' >{row[1]}</td>
                                                <td className='middle' >{row[2]}</td>
                                                <td className='middle' >{row[3]}</td>
                                                <td className='middle' >{row[4]}</td>
                                                <td className='right-edge' >{row[5]}</td>
                                            </tr> 
                                        </tbody>
                                    )
                                })
                            }
                            
                        </table>
                    </div>
                </div>
            </div>
            <div className={`overlay ${blurShown? "overlay-on" : "overlay-off"}`} onClick={() => {
                setBlurShown(!blurShown);
                setMenuShown(!menuShown);
                setColumnMenuShown(!columnMenuShown);
            }}></div>
            <div className={`filter-menu ${menuShown ? "shown" : "hidden"}`} >
                <div className='filter-main-container' >
                    <div className='filter-top'>
                        <p>Filter Chart</p>
                        <button className='menu-exit-button' onClick={() => {
                            setMenuShown(false)
                            setBlurShown(false)
                        }}>Exit</button>
                    </div>
                    <div className='filter-section' >
                        <p className='filter-section-title' >Sports Books</p>
                        <div className='filter-sportsbooks-list' >
                            {
                                sportsbook_data.map((book) => {
                                    return(
                                        <>
                                            <div className='sportsbook-option-container' >
                                                <input type="checkbox" />
                                                <img src={book.book_logo} height="16" width="16" />
                                                <p>{book.book_name}</p>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='odds-filter-section' >
                        <div className='odds-filter-container' >
                            <p>Min Odds</p>
                            <textarea className='odds-filter-input' placeholder='-800' />
                        </div>
                        <div className='odds-filter-container' >
                            <p>Max Odds</p>
                            <textarea className='odds-filter-input' placeholder='+700' />
                        </div>
                    </div>
                    <div className='filter-section' >
                        <div className="slider-container">
                            <div className='slider-top' >
                                <p className="slider-label">Arb Percentage</p>
                                <div className="slider-value-box">{value}%</div>
                                </div>  
                            </div>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                className="slider"
                            />  
                            <div className="slider-values">
                                <span>0%</span>
                                <span>50%</span>
                                <span>100%</span>
                            </div>
                            
                    </div>
                    <div className='filter-section end-section' >
                        <p>Sports</p>
                        <div className='sports-filter-container' >
                            {
                                sports.map((sport) => {
                                    return(
                                        <>
                                            <div className='sports-option' >
                                                <input type='checkbox' />
                                                <p>{sport.name}</p>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className='filter-bottom-container' >
                    <div className='filter-button-section' >
                        <div className='filter-button cancel' onClick={() => {
                            setMenuShown(false);
                            setBlurShown(false);
                        }}>
                            <p>Cancel</p>
                        </div>
                        <div className='filter-button apply' >
                            <p>Show Results</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`filter-menu ${columnMenuShown ? "shown" : "hidden"}`} >
                <div className='filter-main-container column-container' >
                    <div className='filter-top'>
                        <p>Filter Columns</p>
                        <button className='menu-exit-button' onClick={() => {
                            setColumnMenuShown(false);
                            setBlurShown(false);
                        }}>Exit</button>
                    </div>
                    <div className='filter-section' >
                        <p>Columns</p>
                        <div className='sports-filter-container' >
                            {
                                column_names.map((column) => {
                                    return(
                                        <>
                                            <div className='sports-option' >
                                                <input type='checkbox' />
                                                <p>{column}</p>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className='filter-bottom-container' >
                    <div className='filter-button-section' >
                        <div className='filter-button cancel' onClick={() => {
                            setColumnMenuShown(false);
                            setBlurShown(false);
                        }}>
                            <p>Cancel</p>
                        </div>
                        <div className='filter-button apply' >
                            <p>Show Results</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}