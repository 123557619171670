import React, { useState } from 'react';
import "./style-pages/userfunnel3.css";

export default function UserFunnel3({ onNext }) {
    const [selectedSports, setSelectedSports] = useState([]);

    const sports_options = [
        {
            id: "nflfootball",
            name: "🏈 NFL Football",
        },
        {
            id: "nbabasketball",
            name: "🏀 NBA Basketball",
        },
        {
            id: "mlbbaseball",
            name: "⚾️ MLB Baseball",
        },
        {
            id: "ncaafootball",
            name: "🏈 College Football",
        },
        {
            id: "ncaabasketball",
            name: "🏀 College Basketball",
        }
    ]

    const handleSelection = (sportId) => {
        setSelectedSports((prev) =>
          prev.includes(sportId) ? prev.filter((id) => id !== sportId) : [...prev, sportId]
        );
    };
    
    return(
        <div className='sports-content' >
            <div className='sports-funnel-container' >
                <p className='sports-funnel-title' >Select your Favorite Sport(s)</p>
                <p className='sports-funnel-subtitle' >Select all that apply!</p>
                <div className='sport-selection-container' >
                    {
                        sports_options.map((sport) => {
                            return(
                                <div className='sport-select'>
                                    <input type="checkbox" onChange={() => {handleSelection(sport.id)}}/>
                                    <p>{sport.name}</p>
                                </div>
                            )  
                        })
                    }
                </div>
                <div className='sports-continue-button' onClick={() => onNext({ sports: selectedSports })} >
                    <p>Continue</p>
                </div>
            </div>
        </div>
    )
}