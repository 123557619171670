import React, { useState, useCallback } from 'react';
import './style-pages/userfunnel.css';
import TopBarFunnel from './components/TopBarFunnel';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

import UserFunnel1 from './components/UserFunnel1';
import UserFunnel2 from './components/UserFunnel2';
import UserFunnel3 from './components/UserFunnel3';
import UserFunnel4 from './components/UserFunnel4';
import { useNavigate } from 'react-router-dom';

// The new method for creating a user will be 
// Create user in the firebase and an empty row in the database
// if user has not completed funnel, then they are set to an incomplete (false)
// if they have, then a route will be called to fill in their database row
// After which they are taken to the checkout

export default function UserFunnel() {
    const navigate = useNavigate()

    const [userObject, setUserObject] = useState({});
    const [funnelStep, setFunnelStep] = useState(1);
    const [showCheckout, setShowCheckout] = useState(false);

    const handleNext = async (data) => {
        const user = getAuth().currentUser;

        if(funnelStep < 4){
            setUserObject((prev) => ({...prev, ...data}))
            setFunnelStep((prev) => prev + 1);
        } else if (funnelStep == 4){

            const response = await axios.post("https://propicks-data-api.com/v1/complete-user", {
                userObject: userObject,
                data: data,
                gid: user.uid,
            })

            if(response.status == 200){
                navigate('/test-checkout');
            } else {
                console.log("There was an error creating the account.")
            }
            
        }
    }

    return(
        <div className='user-funnel-content' >
            <TopBarFunnel step={funnelStep} />
            { showCheckout ? (
                <>
                    <div>
                        <p>You have reached the checkout...</p>
                        <p>{userObject.age}</p>
                        <p>{userObject.username}</p>
                    </div>
                </>
            ) : (
                <div className='user-funnel-container' >
                    { funnelStep === 1 && <UserFunnel1 onNext={handleNext} />}
                    { funnelStep === 2 && <UserFunnel2 onNext={handleNext} /> }
                    { funnelStep === 3 && <UserFunnel3 onNext={handleNext} /> }
                    { funnelStep === 4 && <UserFunnel4 onNext={handleNext} />}
                </div>
            )}
        </div>
    )
}