import React, { useState, useRef, useEffect, useCallback } from 'react';
import './style-pages/homedashboard.css';
import TopBar from './components/TopBar';
import HomeRightColumn from './components/HomeRightColumn';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import bets from './assets/coins-dollar.png';
import team_chats from './assets/thread.png';
import up from './assets/up.png';
import pfp from './assets/user_pfp.jpg';
import like from './assets/heart.png';
import comment from './assets/comment.png';
import share from './assets/share-06.png';
import bookmark from './assets/bookmark.png';
import search from './assets/search.png';
import exit from './assets/exit.png';
import nochats from './assets/text-bubble.png'

// going to attempt a dashboard load using the current endpoint
// Will need a new endpoint in the future for better functionality

export default function Dashboard() {
    const navigate = useNavigate();
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');
    const standard = "https://firebasestorage.googleapis.com/v0/b/propicksio.firebasestorage.app/o/defaultProfilePicture%2Fuser_pfp.jpg?alt=media&token=71272afc-3c7b-4d62-8c0d-2c2357be4207";

    const [authenticated, setAuthentication] = useState(false);
    const [user, setUser] = useState({});
    const [posts, setPosts] = useState([]);
    const [dataExists, setDataExists] = useState(false);
    const [userTeams, setUserTeams] = useState([]);
    const [arbCount, setArbCount] = useState(0);
    const [pevCount, setPevCount] = useState(0);
    const [lastDate, setLastDate] = useState();
    const [userExists, setUserExists] = useState(false);
    const [selectedTab, setSelectedTab] = useState("Feed");
    const [text, setText] = useState("");
    const [postStarted, setPostStarted] = useState(false);
    const [characterCount, setCharacterCount] = useState(0);
    const [collapsed, setCollapsed] = useState(false);
    const [mainBlurred, setMainBlurred] = useState(false);
    const [betFinderOpen, setBetFinderOpen] = useState(false);
    const [teamFinderOpen, setTeamFinderOpen] = useState(false);
    const [signUpCTA, setSignUpCTA] = useState(false);
    const [shareScreen, setShareScreen] = useState(false);
    const [pfp, setPfp] = useState(standard);
    const [loading, setLoading] = useState(false);

    const textareaRef = useRef(null);
    const countDisplayRef = useRef(null);
    const inputAreaRef = useRef(null);
    const buttonAreaRef = useRef(null);
    const upperPostButtonRef = useRef(null);

    const tabNames = ["Feed", "Trending", "Community Notes"];

    const post_data = [
        "post content 1",
        "post content 2",
        "post content 3 - This is what a long post will look like, although the size of the forum will adapt to screen size, we must also accomodate long chats up to 300 characters in length.",
        "post content 4",
        "post content 5",
        "post content 6"
    ]

    const community_notes = [
        {
            "title": "",
            "content": "",
            "date": "",
        },
    ]
      
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                setUserExists(true);
            } else {
                console.log("No user signed in...")
            }
        })

        const textarea = textareaRef.current;
        const countDisplay = countDisplayRef.current;
        
        if (textarea) {
        textarea.style.height = "auto"; // Reset height to recalculate
        textarea.style.height = `${textarea.scrollHeight}px`; // Set new height
        }

        if (countDisplay){
        if(characterCount > 300){
            console.log(characterCount)
            countDisplay.style.color = "#FF9147";
        } else {
            console.log(characterCount)
            countDisplay.style.color = "#B3B3B3";
        }
        }
    }, [text, characterCount]);

    const updateText = (e) => {
        setText(e.target.value);
        setCharacterCount(e.target.value.length);

        if(e.target.value.length > 0){
            setPostStarted(true);
        } else {
            setPostStarted(false);
        }
    }

    const collapseInput = () => {
        const top = inputAreaRef.current;
        const bottom = buttonAreaRef.current;
        const upperPostButton = upperPostButtonRef.current;
        const textarea = textareaRef.current;

        if(!collapsed){
            bottom.style.display = "none";
            upperPostButton.style.display = "block";
            top.style.marginBottom = "6px";
            top.style.height = "28px"
            textarea.style.height = "28px"
            setCollapsed(true);
        } else {
            bottom.style.display = "flex";
            upperPostButton.style.display = "none";
            top.style.marginBottom = "0px";
            top.style.height = 'auto';
            textarea.style.height = 'auto';
            setCollapsed(false);
        }
    }

    const checkUserStatus = () => {
        if(!userExists){
            setMainBlurred(true);
            setSignUpCTA(true);
            setBetFinderOpen(false);
            setTeamFinderOpen(false);
        }
    }

    const createPost = () => {
        checkUserStatus()

        if(characterCount > 300){
            alert("Cannot create post, must have only 300 characters or less.")
        } else {
            setText("");
            setCharacterCount(0);
        }
    }

    const loadDashboard = useCallback(async (gid) => {
        try{
            const response = await axios.post("https://propicks-data-api.com/load-dashboard", {
                gid: gid
            })
    
            let posts = response.data.chats;
    
            if(response.data.user && response.data.user.liked_posts){
                posts.map((chat) => {
                    if(response.data.user.liked_posts.includes(chat.message_id)){
                        posts.is_liked = true;
                    }
                })
            }
        
            setPosts(posts);
            console.log(posts);
    
            setLastDate(posts[posts.length - 1].post_date);
            setArbCount(response.data.counts.arb_count.count);
            setPevCount(response.data.counts.pev_count.count);
            setDataExists(true);
    
            if(response.data.user != undefined || response.data.user != null){
                setUser(response.data.user);
                sessionStorage.setItem('accountData', JSON.stringify(response.data.user));
            }
            setUserTeams(response.data.teams);
            setAuthentication(true)
        } catch (err) {
            console.log("An error has occurred when loading dashbaord.")
            console.log({ message: err.message })
        }
    }, []);

    const auditBetKey = (bet_key) => {
        if(bet_key == 'h2h') return "Moneyline"
        else if (bet_key == "spreads") return "Spread"
        else if (bet_key == "totals") return "Totals"
    };
  
    const auditBetBook = (bet_book) => {
        if(bet_book == "fanduel") return "Fanduel"
        else if (bet_book == "draftkings") return "DraftKings"
        else if (bet_book == "mybookieag") return "MyBookieAG"
        else if (bet_book == "betrivers") return "BetRivers"
        else if (bet_book == "betmgm") return "BetMGM"
        else if (bet_book == "bovada") return "Bovada"
        else if (bet_book == "lowvig") return "LowVig"
        else if (bet_book == "betus") return "BetUS"
        else return bet_book;
    };

    const fetchUserProfile = async (gid) => {
        const session = sessionStorage.getItem("user-session-data");
        let user_session_data = null;

        try {
            user_session_data = session ? JSON.parse(session) : null;
        } catch (err) {
            sessionStorage.removeItem("user-session-data");
        }

        if(user_session_data != null){
            setUser(user_session_data);
            setPfp(user_session_data.pfp);
            setUserExists(true);    
        } else {
            const profile_response = await axios.post("https://propicks-data-api.com/v1/get-user", {
                gid: gid,
            })

            console.log(profile_response.data);

            if(profile_response.status == 200){
                setUser(profile_response.data.user);
                setLoading(false);
                setUserExists(true);

                sessionStorage.setItem('user-session-data', JSON.stringify(profile_response.data.user));

                if(profile_response.data.user){
                    setPfp(profile_response.data.user.pfp);
                }
            } else {
                console.log({ err: "Call not succesful "})
            }
        }
    }

    const promptUserComplete = () => {
        if(user.funnel == false && userExists == true){
            return (
                <>
                    <div className='account-incomplete-container' >
                        <p>Your profile is incomplete! Set up your account now.</p>
                        <div className='incomplete-button' onClick={() => {navigate("/test-funnel")}} >
                            <p>Complete Now!</p>
                        </div>
                    </div>
                </>
            )
        }
    }

    useEffect(() => {
        loadDashboard();

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if(user) {
                fetchUserProfile(user.uid);
            }
        })

        return () => unsubscribe();
    }, [])

    return(
        <div className='home-dashboard'>
            <TopBar />
            <div className='dashboard-main-content'>
                <div className='social-feed-container'>
                    { promptUserComplete() }
                    <div className="forum-menu-container">
                        {tabNames.map((tab) => (
                        <div
                            key={tab}
                            onClick={() => setSelectedTab(tab)}
                            className={`forum-menu-button ${selectedTab === tab ? "current" : ""}`}
                        >
                            {tab}
                        </div>
                        ))}
                    </div>
                    <div className="feed-content">
                        {selectedTab === "Feed" && 
                            <div className='main-feed-content' >
                                <div className='create-post-container'>
                                    <div className='create-post-main' ref={inputAreaRef} onClick={() => {if(collapsed){
                                            collapseInput()
                                        }
                                    }}>
                                        <img className='create-post-pfp' src={pfp} height="28" width="28" />
                                        <textarea className='create-post-input no-detail' ref={textareaRef} value={text} type="text" onChange={(e) => updateText(e)} placeholder='Write a post!'/>
                                        <div className='create-post-right-button'>
                                            <button className={`upper-post-button ${postStarted ? "" : "not-started"}`} ref={upperPostButtonRef} onClick={() => createPost()}>Post</button>
                                        </div>
                                    </div>
                                    <div className='create-post-buttons' ref={buttonAreaRef}>
                                        <div className='create-post-buttons-left-container'>
                                            <button className='create-post-left-button' onClick={() => {
                                                setBetFinderOpen(true);
                                                setTeamFinderOpen(false);
                                                setSignUpCTA(false);
                                                setMainBlurred(!mainBlurred);
                                            }}>
                                                <img src={bets} height="16" width="16" />
                                                <p className='create-post-left-button-text' >Bets</p>
                                            </button>
                                            <button className='create-post-left-button' onClick={() => {
                                                setTeamFinderOpen(true);
                                                setBetFinderOpen(false);
                                                setSignUpCTA(false);
                                                setMainBlurred(!mainBlurred);
                                            }}>
                                                <img src={team_chats} height="16" width="16" />
                                                <p className='create-post-left-button-text' >Teams</p>
                                            </button>
                                            <button className='create-post-left-button' onClick={() => collapseInput()}>
                                                <img src={up} height="16" width="16" />
                                                <p className='create-post-left-button-text' >Hide</p>
                                            </button>
                                        </div>
                                        <div className='create-post-buttons-right-container'>
                                            <div className='create-post-right-button' disabled={true}>
                                                <p className='character-count' ref={countDisplayRef} >
                                                    {characterCount}/300    
                                                </p>
                                            </div>
                                            <div className='create-post-right-button' >
                                                <button className={`post-button ${postStarted ? "" : "not-started"}`} onClick={() => createPost()}>Post</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='social-feed'>
                                    {
                                        posts.map((post) => {

                                            let bet_line_exists = false, bet_line;

                                            if(post.betting_line) {
                                                bet_line = JSON.parse(post.betting_line);
                                                bet_line_exists = true;
                                            }

                                            return(
                                                <div className='user-post-container'>
                                                    <div className='post-header-container'>
                                                        <img src="https://firebasestorage.googleapis.com/v0/b/propicksio.firebasestorage.app/o/profilePictures%2FzgXHJL6xqHWmZhGI5mXc8q0HkUM2?alt=media&token=c2cb7f3c-f011-4315-8e50-c45f8dcffe2f" width="32" height="32" />
                                                        <div className='post-user-info'>
                                                            <p className='post-username-dash' onClick={() => {navigate('/test-profile')}}>{post.user_name}</p>
                                                            <p><span>in</span> {post.forum_name}</p>
                                                        </div>
                                                    </div>
                                                    <div className='post-content' onClick={() => {navigate("/test-post")}}>
                                                        <p>{post.message_data}</p>
                                                    </div>
                                                    { bet_line_exists ? (
                                                        <>
                                                            <div className='bet-content' onClick={() => {navigate("/test-post")}} >
                                                                <p>{auditBetBook(bet_line.book)} - Event name goes here</p>
                                                                <p>{auditBetKey(bet_line.key)}</p>
                                                                <div className='bet-lines-container' >
                                                                    <div className='bet-line'>

                                                                    </div>
                                                                    <div className='bet-line'>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                                    }
                                                    <div className='post-buttons-container'>
                                                        <div className='interact-button' >
                                                            <img src={like} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                            <p>2.5k</p>
                                                        </div>
                                                        <div className='interact-button' onClick={() => {navigate("/test-post")}} >
                                                            <img src={comment} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                            <p>247</p>
                                                        </div>
                                                        <div className='interact-button' onClick={() => {
                                                                setShareScreen(!shareScreen);
                                                                setMainBlurred(!mainBlurred);
                                                            }} >
                                                            <img src={share} height="16" width="16" />
                                                            <p>Share</p>
                                                        </div>
                                                        <div className='interact-button' >
                                                            <img src={bookmark} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                            <p>Save</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                        {selectedTab === "Trending" && 
                            <div className='trending-feed-content' >
                                <div className='social-feed'>
                                    {
                                        post_data.map((post) => {
                                            return(
                                                <div className='user-post-container'>
                                                    <div className='post-header-container'>
                                                        <img src={pfp} width="32" height="32" />
                                                        <div className='post-user-info'>
                                                            <p>Username</p>
                                                            <p><span>in</span> Team Name</p>
                                                        </div>
                                                    </div>
                                                    <div className='post-content'>
                                                        <p>{post}</p>
                                                    </div>
                                                    <div className='post-buttons-container'>
                                                        <div className='interact-button' >
                                                            <img src={like} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                            <p>2.5k</p>
                                                        </div>
                                                        <div className='interact-button' >
                                                            <img src={comment} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                            <p>247</p>
                                                        </div>
                                                        <div className='interact-button' >
                                                            <img src={share} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                            <p>Share</p>
                                                        </div>
                                                        <div className='interact-button' >
                                                            <img src={bookmark} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                            <p>Save</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                        {selectedTab === "Community Notes" && 
                            <div className='no-posts-container-dash' >
                                <img src={nochats} height="96" width="96" />
                                <p className='no-posts-subtitle' >Nothing to see here... It's empty!</p>
                            </div>
                        }
                    </div>
                </div>
                <HomeRightColumn />
            </div>
            <div className={`${mainBlurred ? "main-overlay" : "not-shown"}`} onClick={() => {
                setMainBlurred(!mainBlurred);
                setBetFinderOpen(false);
                setTeamFinderOpen(false);
                setSignUpCTA(false);
                setShareScreen(false);
            }}></div>
            <div className={`${betFinderOpen ? "bet-finder-container" : "not-shown"}`} >
                <div className='search-container-top'>
                    <div className='team-search-title-container' >
                        <p>Attach Betting Line</p>
                        <img src={exit} height="16" width="16" onClick={() => {
                            setMainBlurred(!mainBlurred);
                            setBetFinderOpen(false);
                            setTeamFinderOpen(false);
                            setSignUpCTA(false);
                        }} />
                    </div>
                    <div className='search-container'>
                        <img src={search} height="18" width="18" />
                        <textarea className='search-input' placeholder='Search' />
                    </div>
                </div>
                {/* You search for events to find betting lines*/}
                <div className='search-results-container' >
                    {/* You can expand an event to see its betting lines per book */}
                    <div className='event-search-result' >
                        <h4>Cleveland Browns vs. Atlanta Falcons</h4>
                        <p>Money Line</p>
                        <div className='event-bet-book'>
                            {/* the sports book logo goes here */}
                            <div className='event-book-bet-line' >
                                <p>+100</p>
                            </div>
                            <div className='event-book-bet-line' >
                                <p>-110</p>
                            </div>
                        </div>
                        {/* Within a given book you will have options for moneyline, spread, etc */}
                        <p>Spreads</p>
                        <div className='event-bet-book'>
                            {/* the sports book logo goes here */}
                            <div className='event-book-bet-line' >
                                <p>+100</p>
                            </div>
                            <div className='event-book-bet-line' >
                                <p>-110</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='search-container-bottom'>
                    <div className='dash-add-button'>
                        <p>Add</p>
                    </div>
                </div>
            </div>
            <div className={`${teamFinderOpen ? "team-finder-container" : "not-shown"}`} >
                <div className='search-container-top'>
                    <div className='team-search-title-container' >
                        <p>Attach Team Forum</p>
                        <img src={exit} height="16" width="16" onClick={() => {
                            setMainBlurred(!mainBlurred);
                            setBetFinderOpen(false);
                            setTeamFinderOpen(false);
                            setSignUpCTA(false);
                        }} />
                    </div>
                    <div className='search-container'>
                        <img src={search} height="18" width="18" />
                        <textarea className='search-input' placeholder='Search' />
                    </div>
                </div>
                <div className='search-results-container' >
                    <div className='team-search-result selected-team' >
                        <p>Sports Team</p>
                    </div>
                    <div className='team-search-result' >
                        <p>Sports Team</p>
                    </div>
                </div>
                <div className='search-container-bottom'>
                    <div className='dash-add-button'>
                        <p>Add</p>
                    </div>
                </div>
            </div>
            <div className={`${signUpCTA ? "sign-up-cta" : "not-shown"}`} >
                <div className='cta-header-container' >
                    <p>You're not signed in...</p>
                    <p>Create an account today!</p>
                </div>
                <div className='cta-buttons-container' >
                    <div className='no-user-container' >
                        <button className='nav-profile-button signup' onClick={() => {navigate("/test-signup")}} >Sign Up</button>
                        <button className='nav-profile-button signin' onClick={() => {navigate("/test-signin")}} >Log In</button>
                    </div>
                </div>
            </div>
            <div className={`${shareScreen ? "share-screen" : "not-shown"}`} >
                <div className='share-header' >
                    <p>Share</p>
                </div>
                <div className='share-link-container' >
                    <textarea className='share-link' />
                </div>
                <div className='share-buttons-container'>
                    <div className='x-share-button' >
                        <p>Share to X</p>
                    </div>
                    <div className='reddit-share-button' >
                        <p>Share to Reddit</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

/*
"{"betting_line": [[{"name": "Cleveland Browns", "price": 185}, {"name": "Kansas City Chiefs", "price": -220}]], "book": "betus", "key": "h2h"}"
*/