import React from 'react';
import Sidebar from './components/Sidebar';
import './style-pages/plans.css';

export default function Plans() {

    const handleLogin = (loginUrl) => {
        console.log("Login will go here: ", loginUrl)
    }

    return(
        <div className='pricing-dash-content'>
            <Sidebar />
            <div className="main-plans-content">
                <h1 className='pricing-content-header'>Choose your Plan</h1>
                <div className="pricing-cards">
                    <div className="pricing-card">
                        <h2 className='pricing-card-header'>Basic</h2>
                        <div className="price">
                            <span className="currency">$</span>19.99
                            <span className="per-month">Monthly</span>
                        </div>
                        <p className='pricing-card-p' >Beginner Tools</p>
                        <button onClick={() => {handleLogin("https://buy.stripe.com/test_14k9AT5gCdL4cqQ000")}}>Start Free Trial</button>
                        <ul>
                            <li>Positive EV Early Access</li>
                            <li>Arbitrage Early Access</li>
                            <li>Sportsbook Screener</li>
                            <li>Social Community Early Access</li>
                            <li>Betting Calculators</li>
                        </ul>
                    </div>
                    <div className="pricing-card">
                        <h2>Pro</h2>
                        <div className="price">
                            <span className="currency"></span>Coming Soon
                            <span className="per-month">In 2025</span>
                        </div>
                        <p>Advanced Tools</p>
                        <button onClick={() => {handleLogin("http://localhost:3000/dashboard")}}>Try Basic Plan</button>
                        <ul>
                            <li>All Basic Tools</li>
                            <li>Push Notifications</li>
                            <li>Exclusive Discord Access</li>
                            <li>Positive EV</li>
                            <li>Arbitrage Betting</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}