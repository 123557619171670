import React, { useEffect, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from './components/Sidebar';
import './style-pages/betscanner.css';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default function BetScanner() {
    const navigate = useNavigate();
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');

    const [authenticated, setAuthentication] = useState(false);
    const [user, setUser] = useState({});

    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if(user){
          setAuthentication(true);
          getUserInfo(user.uid);
        } else {
          console.log("no user :(");
        }
      })
      // propogateBetData();
    }, [])

    const getUserInfo = useCallback(async (gid) => {
      if (session != undefined) {
        setUser(JSON.parse(session));
      } else {
        await axios.post("https://propicks-data-api.com/get-user", {
            gid: gid,
          })
            .then((response) => {
              setUser(response.data.postgres_response);
              sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
            })
            .catch((err) => {
              console.log({ err: err });
            })
      }
    });

    if(!authenticated){
      return(
        <div className='scanner-content'>
          <Sidebar />
          <div className='main-content'>
              <div className="login-cta-div" >
                <h3>Don't have an account? Create one now!</h3>
                <button>Sign In</button>
              </div>
            </div>
        </div>
      )
    }

    return (
        <div className='scanner-content'>
            <Sidebar />
            <main className='main-content'>
                <h2>Bet Scanner</h2>
                <p>Under Construction... Coming Soon!</p>
                <div className='button-container'>
                    <button onClick={() => (navigate('/signin'))} > Take Me Home! </button>
                </div>
            </main>
            
        </div>
    )
}
