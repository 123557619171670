import React from 'react';
import './style-pages/testsettings.css';
import TopBar from './components/TopBar';

export default function TestSettings() {
    return(
        <div className='test-settings-content' >
            <TopBar />
            <div className='test-settings-container' >
                
            </div>
        </div>
    )
}