import React, { useState, useEffect } from 'react'
import Sidebar from './components/Sidebar'
import axios from 'axios';
import './style-pages/chat.css'
import { useNavigate, createSearchParams  } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import heart_white from './assets/heart_white.svg';
import heart_red from './assets/heart_red.svg';
import reply from './assets/reply.svg';

export default function Chat() {
    const navigate = useNavigate();
    const auth = getAuth();

    const [chatId, setChatId] = useState();
    const [chat, setChat] = useState({});
    const [dataExists, setDataExists] = useState(false);
    const [user, setUser] = useState();
    const [userMessage, setUserMessage] = useState();

    const [disabled, setDisabled] = useState(true);

    const respondToMessage = (user_name) => {
        setUserMessage(`@${user_name} `)
    }

    const likeMessage = (message_id) => {
        console.log(`User: ${user.gid} liked message: ${message_id}`)
    }

    const openUserProfile = (user_gid) => {
        const newParams = createSearchParams({
            gid: user_gid
        });
        
        navigate({
            pathname: '/userProfile',
            search: `?${newParams.toString()}`
        });
    }

    const getChatInfo = async () => {
        const queryParams = new URLSearchParams(window.location.search)
        const id = queryParams.get("id")
        setChatId(id);

        const response = await axios.post("https://propicks-data-api.com/get-chat", {
            chat_id: id
        })

        console.log(response.data)
        setChat(response.data);
        setDataExists(true);
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(user){
                setDisabled(false);
            }
        })

        getChatInfo()
    }, [])

    if(!dataExists){
        return(
            <div className='chat-page-display'>
            <Sidebar />
            <div className='main-content'>
                <button onClick={() => {navigate(-1)}}> Back </button>
                <p>
                    Loading...
                </p>
            </div>
        </div>
        )
    }

    return(
        <div className='chat-page-display'>
            <Sidebar />
            <div className='main-content'>
                <button onClick={() => {navigate(-1)}} style={{width: '100px'}}> Back </button>
                <div key={chat.message_id} className='chat-reply-stream-message'>
                    <p className='chat-user' ><a style={{cursor: 'pointer'}} onClick={() => {openUserProfile(chat.user_gid)}}>{chat.user_name}</a></p>
                    <p className='chat-content'>{chat.message_data}</p>
                    <div className='button-div-chats' >
                        <button className="chat-button-like" disabled={disabled} onClick={() => {likeMessage(chat.message_id)}} ><img src={heart_white} height="20" width="20" /></button>
                        <button className="chat-button-reply" disabled={disabled} onClick={() => {respondToMessage(chat.user_name)}} ><img src={reply} height="20" width="20" /></button>
                    </div>
                </div>
            </div>
        </div>
    )
}