import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ContactSupport() {
    const navigate = useNavigate();

    return (
        <div>
            <p>
                Something has gone wrong... Please contact support at <span style={{color: "blue"}}>contact@propicks.io</span>.
            </p>
            <button onClick={() => {navigate("/")}}>
                Return Home
            </button>
        </div>
    )
}
