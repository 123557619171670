import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Process from './user-flow-pages/Process';
import MainDashboard from './dash-pages/MainDashboard';
import PaymentPage from './user-flow-pages/PaymentPage';
import PositiveEV from './dash-pages/PositiveEV';
import BetScanner from './dash-pages/BetScanner';
import Plans from './dash-pages/Plans';
import ContactSupport from './user-flow-pages/ContactSupport';
import SignUp from './user-flow-pages/SignUp';
import SignIn from './user-flow-pages/SignIn';
import GeneralSocial from './dash-pages/GeneralSocial';
import DailyPickSocial from './dash-pages/DailyPickSocial';
import TeamsChat from './dash-pages/TeamsChat';
import TeamChatFeed from './dash-pages/TeamChatFeed';
import Settings from './dash-pages/Settings';
import UserProfile from './dash-pages/UserProfile';
import Chat from './dash-pages/Chat';
import GettingStarted from './dash-pages/GettingStarted';
import CheckContainer from './user-flow-pages/CheckContainer';
import ManagePlan from './dash-pages/ManagePlan';
import Cancel from './dash-pages/Cancel';
import ToolsHome from './dash-pages/ToolsHome';
import Groups from './dash-pages/Groups';
import ChangeBio from './dash-pages/ChangeBio';
import ProfilePicture from './dash-pages/ProfilePicture';
import Dashboard from './dash-pages/Dashboard';
import TestArbitrage from './dash-pages/TestArbitrage';
import TestPositiveEV from './dash-pages/TestPositiveEV';
import TestScanner from './dash-pages/TestScanner';
import Notifications from './dash-pages/Notifications';
import TestSignUp from './user-flow-pages/TestSignUp';
import Terms from './dash-pages/Terms';
import Privacy from './dash-pages/Privacy';
import TestSignIn from './user-flow-pages/TestLogIn';
import TestPlans from './dash-pages/TestPlans';
import Sportsbooks from './dash-pages/Sportsbooks';
import TestProfile from './dash-pages/TestProfile.js';
import TestAccount from './dash-pages/TestAccount.js';
import TestSettings from './dash-pages/TestSettings.js';
import UserFunnel from './user-flow-pages/UserFunnel.js';
import TestChat from './dash-pages/TestChat.js';
import UserCheckout from './user-flow-pages/UserCheckout.js';

function App() {
  return (
    <BrowserRouter className="router">
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/process" element={<Process />} />
          <Route path="/pay" element={<PaymentPage />} />
          <Route path="/error" element={<ContactSupport/> } />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn /> } />
          <Route path="/explore" element={<GeneralSocial />} />
          <Route path="/dailypick" element={<DailyPickSocial />} />
          <Route path="/teams" element={<TeamsChat />} />
          <Route path="/teamchat" element={<TeamChatFeed />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/positiveev" element={<PositiveEV />} />
          <Route path="/betscanner" element={<BetScanner />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/userProfile" element={<UserProfile />} />
          <Route path="/gettingstarted" element={<GettingStarted />} />
          <Route path="/checkoutStatus" element={<CheckContainer />} />
          <Route path="/planManagement" element={<ManagePlan />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route path="/tools" element={<ToolsHome />} />
          <Route path="/groups" element={<Groups />} />
          <Route path="/change-bio" element={<ChangeBio />} />
          <Route path="/change-profile-picture" element={<ProfilePicture />} />
          <Route path="/old-dashboard" element={<MainDashboard />} />
          <Route path="/test-post" element={<TestChat />} />
          <Route path="/test-arbitrage" element={<TestArbitrage />} />
          <Route path="/test-pev" element={<TestPositiveEV />} />
          <Route path="/test-scanner" element={<TestScanner />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/test-signup" element={<TestSignUp />} />
          <Route path="/test-funnel" element={<UserFunnel />} />
          <Route path="/test-checkout" element={<UserCheckout />} />
          <Route path="/test-signin" element={<TestSignIn />} />
          <Route path="/test-plans" element={<TestPlans />} />
          <Route path="/test-account" element={<TestAccount />} />
          <Route path="/test-profile" element={<TestProfile />} />
          <Route path="/test-settings" element={<TestSettings />} />
          <Route path="/offers" element={<Sportsbooks />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
