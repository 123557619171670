import React, { useState } from 'react';
import './component-styles/homerightcolumn.css';

import date from '../assets/calendar.png';
import time from '../assets/clock.png';
import enter from '../assets/arrow-right.png';
import arrow from '../assets/arrow2.png';
import live from '../assets/live.png';
import betmgm from '../assets/betmgm.png';
import betus from '../assets/betus.png';
import bovada from '../assets/bovada.png';
import draftkings from '../assets/draftkings.png';
import fanduel from '../assets/fanduel.png';

import browns from '../assets/cleveland_browns.png';
import falcons from '../assets/atlanta_falcons.png';
import { useNavigate } from 'react-router-dom';

export default function HomeRightColumn() {
    const [isOn, setIsOn] = useState(false);
    const navigate = useNavigate();

    const games_data = [
        {
            "event_id": "1234567",
            "title": "NFL Championship",
            "date": "Mon 14th",
            "time": "8:00pm",
            "team_one": "Cleveland Browns",
            "team_two": "Atlanta Falcons",
        },
        {
            "event_id": "1234567",
            "title": "NFL Championship",
            "date": "Mon 14th",
            "time": "8:00pm",
            "team_one": "Cleveland Browns",
            "team_two": "Atlanta Falcons",
        },
    ]
    
    const sportsbook_data = [
        {
            "logo": draftkings,
            "offer": "Up to $500 Bonus Bet",
            "suboffer": "Exclusive Offer Available",
        },
        {
            "logo": fanduel,
            "offer": "Up to $500 Bonus Bet",
            "suboffer": "Exclusive Offer Available",
        },
        {
            "logo": betmgm,
            "offer": "Up to $500 Bonus Bet",
            "suboffer": "Exclusive Offer Available",
        },
        {
            "logo": betus,
            "offer": "Up to $500 Bonus Bet",
            "suboffer": "Exclusive Offer Available",
        },
        {
            "logo": bovada,
            "offer": "Up to $500 Bonus Bet",
            "suboffer": "Exclusive Offer Available",
        },
    ]

    return(
        <div className='games-detail-container'>
            <div 
                className={`toggle-container `}
                onClick={() => setIsOn(!isOn)}
            >
                <span className="toggle-label left">Upcoming Games</span>
                <span className="toggle-label right">Live Games <img src={live} height="16" width="16" /> </span>   
                <div className={`toggle-button ${isOn ? "on" : ""}`}></div>
            </div>
            <div className='game-details-column' >
                <div className='game-details-frame'>
                    {
                        games_data.map((event) => {
                            return(
                                <div className='game-details' key={event.event_id} >
                                    <div className='game-details-title'>
                                        <p className='event-title' >{event.title}</p>
                                        <div className='time-date-container' >
                                            <img src={date} height="14" width="14" />
                                            <p>{event.date}</p>
                                            <p> - </p>
                                            <img src={time} height="14" width="14" />
                                            <p>{event.time}</p>
                                        </div>
                                    </div>
                                    <div className='game-details-info' >
                                        <div className='team-container upper'>
                                            <img className='team-logo' src={browns} height="24" width="24" />
                                            <p className='team-one' >
                                                {event.team_one}
                                            </p>
                                        </div>
                                        <div className='team-container lower'>
                                            <img className='team-logo' src={falcons} height="24" width="24" />
                                            <p className='team-two' >
                                                {event.team_two}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='see-more-button' onClick={() => {navigate("/test-scanner")}} >
                                        <p>See Picks and Stats</p>
                                        <img src={enter} width="16" height="16" />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='see-more-games-button' onClick={() => {navigate('/test-scanner')}}  >
                        <p>Find More Games</p>
                        <img src={enter} height="18" width="18" />
                    </div>
                </div>
                <div className='sports-book-container' >
                    <div className='sports-books-title-container' >
                        <p> Sportsbook Offers </p>
                        <div className='more-books-button' onClick={() => {navigate("/offers")}}>
                            <p>Find More</p>
                            <img src={arrow} height="10" width="10" />
                        </div>
                    </div>
                    <div className='sports-book-offer-container' >
                        {
                            sportsbook_data.map((book) => {
                                return(
                                    <div className='sports-book-offer' >
                                        <img src={book.logo} height="40" width="40" />
                                        <div className='offer-title-containter' >
                                            <p className='offer-title'>{book.offer}</p>
                                            <p className='offer-subtitle'>{book.suboffer}</p>
                                        </div>
                                        <div className='claim-offer-button'>
                                            <p>Claim Now</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>    
    )
}