import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import "./component-styles/topbar.css";
import { onAuthStateChanged, getAuth, signOut } from 'firebase/auth';
import axios from 'axios';

import logo from '../assets/logo2.png';
import home from '../assets/home-02.png';
import arb from '../assets/up.png';
import pev from '../assets/pev.png';
import scanner from '../assets/scan.png';
import plans from '../assets/credit-card-02.png';
import search from '../assets/search.png';
import notifications from '../assets/bell-03.png';

export default function TopBar() {
    const standard = "https://firebasestorage.googleapis.com/v0/b/propicksio.firebasestorage.app/o/defaultProfilePicture%2Fuser_pfp.jpg?alt=media&token=71272afc-3c7b-4d62-8c0d-2c2357be4207";

    const [profilePopout, setProfilePopout] = useState(false);
    const [user, setUser] = useState({});
    const [userExists, setUserExists] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pfp, setPfp] = useState(standard);

    const navigate = useNavigate();
    const auth = getAuth();

    const logOutUser = () => {
        signOut(auth)
            .then(() => {
                setUserExists(false);
                setUser({});
                setPfp(standard);

                sessionStorage.clear();

                navigate("/");
            })
            .catch((err) => {
                console.log({ err: err.message });
            })
    }

    const fetchUserProfile = async (gid) => {
        const session = sessionStorage.getItem("user-session-data");
        let user_session_data = null;

        try {
            user_session_data = session ? JSON.parse(session) : null;
        } catch (err) {
            sessionStorage.removeItem("user-session-data");
        }

        if(user_session_data != null){
            setUser(user_session_data);
            setPfp(user_session_data.pfp);
            setUserExists(true);    
        } else {
            const profile_response = await axios.post("https://propicks-data-api.com/v1/get-user", {
                gid: gid,
            })

            console.log(profile_response.data);

            if(profile_response.status == 200){
                setUser(profile_response.data.user);
                setLoading(false);
                setUserExists(true);

                sessionStorage.setItem('user-session-data', JSON.stringify(profile_response.data.user));

                if(profile_response.data.user){
                    setPfp(profile_response.data.user.pfp);
                }
            } else {
                console.log({ err: "Call not succesful "})
            }
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if(user){
                fetchUserProfile(user.uid)
            }
        })

        return () => unsubscribe();
    }, [])

    return(
        <div className='topbar-content' >
            <div className='logo-container' onClick={() => navigate("/")}>
                <img src={logo} height="24" width="24" />
                <h4 className='propicks-title'>ProPicks</h4>
            </div>
            <div className='nav-container'>
                <div className='nav-button' onClick={() => {navigate('/')}} >
                    <img src={home} height="20" width="20" />
                    <p className='nav-button-title' >Home</p>
                </div>
                <div className='nav-button' onClick={() => {navigate('/test-arbitrage')}} >
                    <img src={arb} height="20" width="20" />
                    <p className='nav-button-title' >Arbitrage</p>
                </div>
                <div className='nav-button' onClick={() => {navigate('/test-pev')}} >
                    <img src={pev} height="20" width="20" />
                    <p className='nav-button-title' >Positive EV</p>
                </div>
                <div className='nav-button' onClick={() => {navigate('/test-scanner')}} >
                    <img src={scanner} height="20" width="20" />
                    <p className='nav-button-title' >Bet Scanner</p>
                </div>
                <div className='nav-button' onClick={() => {navigate('/test-plans')}} >
                    <img src={plans} height="20" width="20" />
                    <p className='nav-button-title' >Plans</p>
                </div>
            </div>
            <div className='right-side-container'>
                <div className='profile-container'>
                    <div className='notifications-container'>
                        <img src={notifications} onClick={() => {navigate('/notifications')}} height="20" width="20" />
                    </div>
                    <img className='user-profile-img' src={pfp} height="24" width="24" onClick={() => setProfilePopout(!profilePopout)} />
                </div>    
            </div>
            <div className={`profile-popout ${userExists ? "user-signed-in" : "not-signed-in"} ${profilePopout ? "shown" : "hidden"}`} >
                { userExists ? (
                    <div className='user-container' >
                        <div className='user-info-popout' >
                            <p>{user.name}</p>
                        </div>
                        <button className='nav-profile-button signin' onClick={() => {navigate("/test-account")}} >Profile</button>
                        <button className='nav-profile-button signin' onClick={() => {navigate("/notifications")}} >Notifications</button>
                        <button className='nav-profile-button signin' onClick={() => {navigate("/test-settings")}} >Settings</button>
                        <button className='nav-profile-button signin' onClick={() => {logOutUser()}} >Log Out</button>
                    </div>
                ) : (
                    <div className='no-user-container' >
                        <button className='nav-profile-button signup' onClick={() => {navigate("/test-signup")}} >Sign Up</button>
                        <button className='nav-profile-button signin' onClick={() => {navigate("/test-signin")}} >Log In</button>
                    </div>
                )}
            </div>
            <div className={`profile-overlay ${profilePopout ? "shown": "hidden"}`} onClick={() => {setProfilePopout(!profilePopout)}} />
        </div>
    )
}