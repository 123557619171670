import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <Auth0Provider
      domain="dev-3bjkpb66o6hvl8xc.us.auth0.com"
      clientId="l3bWIn8pwKITjSkhXexN8TAhDVx5Lmg3"
    >
      <App />
    </Auth0Provider>
  </>
);

/*
<Route path="/dashboard" element={<Layout />} >
  <Route index element={<HomeFront />} />
  <Route path="/dashboard/" element={<HomeFront />} />
</Route>
*/

/*
<Auth0Provider
      domain="dev-3bjkpb66o6hvl8xc.us.auth0.com"
      clientId="l3bWIn8pwKITjSkhXexN8TAhDVx5Lmg3"
      authorizationParams={{
        redirect_uri: "http://localhost:3000/process",
      }}
    >
*/