import React from 'react';
import './sidebar.css';
import Logo from '../assets/PP_Logo.png';
import { useNavigate } from 'react-router-dom';

import home from '../assets/home_light.svg';
import search from '../assets/search.svg';
import message from '../assets/message-circle.svg';
import arb from '../assets/arrow-up-circle.svg';
import plus from '../assets/plus.svg';
import scan from '../assets/book.svg';
import profile from '../assets/user.svg';
import settings from '../assets/settings.svg';
import plans from '../assets/shopping-bag.svg';

const Sidebar = () => {
    const navigate = useNavigate();

    return (
        <aside className="sidebar">
            <div className="sidebar-header">
                <img src={Logo} alt="Logo" className="logo" />
                <h1>ProPicks</h1>
            </div>
            <nav className="nav">
                <ul>
                <li><button className='sidebar-button' onClick={() => (navigate('/'))} ><img src={home} style={{marginBottom: '-2px', marginRight: '1px'}} /> Home</button></li>
                <li><button className='sidebar-button' onClick={() => (navigate('/general'))} ><img src={search} style={{marginBottom: '-2px', marginRight: '1px'}} /> Explore</button></li>
                <li><button className='sidebar-button' onClick={() => (navigate('/teams'))} ><img src={message} style={{marginBottom: '-2px', marginRight: '1px'}} /> Team Chat</button></li>
                <div className='sidebar-divider'></div>
                <li><button className='sidebar-button' onClick={() => (navigate('/arbitrage'))} ><img src={arb} style={{marginBottom: '-2px', marginRight: '1px'}} /> Arbitrage</button></li>
                <li><button className='sidebar-button' onClick={() => (navigate('/positiveev'))} ><img src={plus} style={{marginBottom: '-2px', marginRight: '1px'}} /> Positive EV</button></li>
                <li><button className='sidebar-button' onClick={() => (navigate('/betscanner'))} ><img src={scan} style={{marginBottom: '-2px', marginRight: '1px'}} /> Bet Scanner</button></li>
                <div className='sidebar-divider'></div>
                <li><button className='sidebar-button' onClick={() => (navigate('/plans'))}><img src={plans} style={{marginBottom: '-2px', marginRight: '1px'}} /> Plans</button></li>
                <li><button className='sidebar-button' onClick={() => (navigate('/profile'))} ><img src={profile} style={{marginBottom: '-2px', marginRight: '1px'}} /> Profile</button></li>
                <li><button className='sidebar-button' onClick={() => (navigate('/settings'))} ><img src={settings} style={{marginBottom: '-2px', marginRight: '1px'}} /> Settings</button></li>
                </ul>
            </nav>
        </aside>
    );
};

export default Sidebar;
