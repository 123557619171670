import React from "react";
import Sidebar from "./components/Sidebar";
import { useNavigate } from "react-router-dom";
import "./style-pages/settings.css";

export default function Settings() {
    const navigate = useNavigate();

    return (
        <div className="settings-content">
            <Sidebar />
            <main className='main-content'>
                <h2>Settings</h2>
                <h3 className="option-header" >Account Settings</h3>
                <div className="option-container">
                    <p>Change Bio: </p>
                    <button className="option-button" onClick={() => {navigate("/change-bio")}} >Edit Bio</button>
                </div>
                <div className="option-container">
                    <p>Add Profile Picture: </p>
                    <button className="option-button extra-long" onClick={() => {navigate("/change-profile-picture")}}>Change Profile Picture</button>
                </div>
                <div className="option-container">
                    <p>Cancel Subscription: </p>
                    <button className="option-button">Cancel</button>
                </div>
                <div className="option-container">
                    <p>Log Out: </p>
                    <button className="option-button" >Log Out</button>
                </div>
                <h3 className="option-header" >Data Settings</h3>
                <p>For more information about the data we collect, please view our terms of service and privacy policy.</p>
                <h3 className="option-header" >Site Information</h3>
                <div className="option-container">
                    <p>Developer Logs & Updates: </p>
                    <button className="option-button long" >Dev Logs</button>
                </div>
                <h3 className="option-header" >Danger Zone </h3>
                <div className="option-container">
                    <p>Delete Account: </p>
                    <button className="option-button long danger">Delete Account</button>
                </div>
            </main>
        </div>
    )
}