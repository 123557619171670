// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #1e1e1e; /* Black */
  --secondary-color: #141414; /* Dark Grey */
  --accent-color: #00d8ff; /* Cyan */
  --text-color: #ffffff; /* White text */
  --nav-height: 60px;
}

::-webkit-scrollbar {
  width: 12px; /* Width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #1a1a1a; /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: #444444; /* Thumb color */
  border-radius: 6px; /* Rounded corners */
  border: 3px solid #1a1a1a; /* Space around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555555; /* Thumb color on hover */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB,EAAE,UAAU;EACpC,0BAA0B,EAAE,cAAc;EAC1C,uBAAuB,EAAE,SAAS;EAClC,qBAAqB,EAAE,eAAe;EACtC,kBAAkB;AACpB;;AAEA;EACE,WAAW,EAAE,kCAAkC;AACjD;;AAEA;EACE,mBAAmB,EAAE,gBAAgB;AACvC;;AAEA;EACE,yBAAyB,EAAE,gBAAgB;EAC3C,kBAAkB,EAAE,oBAAoB;EACxC,yBAAyB,EAAE,2BAA2B;AACxD;;AAEA;EACE,yBAAyB,EAAE,yBAAyB;AACtD","sourcesContent":[":root {\n  --primary-color: #1e1e1e; /* Black */\n  --secondary-color: #141414; /* Dark Grey */\n  --accent-color: #00d8ff; /* Cyan */\n  --text-color: #ffffff; /* White text */\n  --nav-height: 60px;\n}\n\n::-webkit-scrollbar {\n  width: 12px; /* Width of the entire scrollbar */\n}\n\n::-webkit-scrollbar-track {\n  background: #1a1a1a; /* Track color */\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #444444; /* Thumb color */\n  border-radius: 6px; /* Rounded corners */\n  border: 3px solid #1a1a1a; /* Space around the thumb */\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background-color: #555555; /* Thumb color on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
