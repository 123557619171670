import React, { useState, useEffect } from 'react';
import TopBar from './components/TopBar';
import './style-pages/testsignup.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

import logo from './assets/logo.png';
import mail from './assets/mail.png';
import key from './assets/key.png';
import eye from './assets/eye.png';

export default function TestSignUp() {
    const navigate = useNavigate();
    const auth = getAuth();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordView, setPasswordView] = useState("password")
    const [passTooShort, setPassTooShort] = useState(false);
    const [userExists, setUserExists] = useState(false);
    const [createUserLoading, setCreateUserLoading] = useState(false);

    const togglePassView = () => {
        if(passwordView == "password"){
            setPasswordView("text")
        } else {
            setPasswordView("password")
        }
    }

    const handleEmail = ( e ) => {
        setEmail(e)
    }

    const handlePassword = ( e ) => {
        setPassword(e)
        if(e.length < 8 && e.length != 0) setPassTooShort(true);
        else setPassTooShort(false);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          createUser();
        }
      };

    const createUser = async () => {
        if(email.length == 0 || password.length == 0) {
            alert("Please complete username and password.");
            return;
        }

        setCreateUserLoading(true);

        let currentDate = new Date();
        let currentUTCTimestamp = currentDate.toUTCString();

        createUserWithEmailAndPassword(auth, email, password)
            .then(async (user) => {
                const response = await axios.post("https://propicks-data-api.com/v1/create-user", {
                    gid: user.user.uid,
                    email: email,
                    created: currentUTCTimestamp,
                })

                if(response.status != 200 ) throw "An error has occured in the signup";
            })
            .then(() => {
                navigate("/test-funnel");
            })
            .catch((err) => {
                console.log({ err: err.message });
            })
    }

    const signOutUser = async () => {
        signOut(auth)
            .then(() => {
                navigate('/')
            })
            .catch((err) => {
                console.log({ err: err.message })
            })
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(user){
                setUserExists(true);
            }
        })
    })

    if(userExists && !createUserLoading){
        return(
            <div className='test-signup-content' >
                <TopBar />
                <div className='signup-container' >
                    <p className='user-exists-title' >You are already signed in!</p>
                    <p className='user-exists-subtitle' >Do you want to sign out? Or head to the dashboard?</p>
                    <div className='user-exists-buttons' >
                        <div className='sign-out-button' onClick={() => {signOutUser()}}>
                            <p>Sign Out</p>
                        </div>
                        <div className='to-dash-button' onClick={() => {navigate('/')}}>
                            <p>Dashboard</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className='test-signup-content'>
            <TopBar />
            <div className='signup-container'>
                <div className='header-group' >
                    <img src={logo} height="60" width="60" />
                    <p className='header-title' >Get Started with ProPicks</p>
                    <p className='header-subtitle' >Join ProPicks to get insights, odds, and top picks for smarter bets!</p>
                </div>
                <div className='email-group'>
                    <p className='email-title'>Email Address</p>
                    <div className='email-input-group'>
                        <img src={mail} height="16" width="18" />
                        <textarea className='email-input-textarea' placeholder="Enter Email Address" onChange={(e) => {handleEmail(e.target.value)}}></textarea>
                    </div>
                </div>
                <div className='password-group'>
                    <p className='password-title'>Password</p>
                    <div className='password-input-group'>
                        <img src={key} height="16" width="18" />
                        <input className='password-input-textarea' type={passwordView} placeholder="Enter a Strong Password" onChange={(e) => {handlePassword(e.target.value)}} onKeyDown={handleKeyDown} />
                        <img src={eye} height="20" width="20" onClick={() => {togglePassView()}}/>
                    </div>
                    <p className={`password-tip ${passTooShort ? "tip-active" : ""}`} >Must be at least 8 characters.</p>
                </div>
                <div className='bottom-group'>
                    <p className='terms-title' >By signing up, you accept the <span onClick={() => {navigate("/terms")}}>Terms & Conditions</span> and confirm that you have read our <span onClick={() => {navigate("/privacy")}}>Privacy Policy</span>.</p>
                    <button className='signup-button' onClick={() => {createUser()}}>Create Account</button>
                    <p className='returning-title' >Already have an account? <span onClick={() => {navigate("/test-signin")}}>Click here!</span></p>
                </div>
            </div>
        </div>
    )
}