// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container-games-module {
    width: 100%;
    overflow: auto;
    max-height: calc(90vh - 80px);
    position: relative;
    margin-top: 5px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

.table-container-games-module::-webkit-scrollbar {
    display: none;
}
  
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #161b22;
    border-radius: 10px;
  }
  
  .table-header-games-module th {
    position: sticky;
    top: 0;
    background-color: #50326b;
    color: #c9d1d9;
    padding: 10px;
  }
  
  tbody td {
    padding: 10px;
    border: 1px solid #30363d;
    color: #c9d1d9;
  }
  
  .cell-content {
    display: flex;
    flex-direction: column;
  }
  
  tr:hover td {
    background-color: #21262d;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/dash-pages/components/gamemodule.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;IACd,6BAA6B;IAC7B,kBAAkB;IAClB,eAAe;IACf,wBAAwB;IACxB,qBAAqB;EACvB;;AAEF;IACI,aAAa;AACjB;;EAEE;IACE,WAAW;IACX,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,MAAM;IACN,yBAAyB;IACzB,cAAc;IACd,aAAa;EACf;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;IACzB,eAAe;EACjB","sourcesContent":[".table-container-games-module {\n    width: 100%;\n    overflow: auto;\n    max-height: calc(90vh - 80px);\n    position: relative;\n    margin-top: 5px;\n    -ms-overflow-style: none;\n    scrollbar-width: none;\n  }\n\n.table-container-games-module::-webkit-scrollbar {\n    display: none;\n}\n  \n  table {\n    width: 100%;\n    border-collapse: collapse;\n    background-color: #161b22;\n    border-radius: 10px;\n  }\n  \n  .table-header-games-module th {\n    position: sticky;\n    top: 0;\n    background-color: #50326b;\n    color: #c9d1d9;\n    padding: 10px;\n  }\n  \n  tbody td {\n    padding: 10px;\n    border: 1px solid #30363d;\n    color: #c9d1d9;\n  }\n  \n  .cell-content {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  tr:hover td {\n    background-color: #21262d;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
