import React, { useEffect, useState } from 'react';
import Sidebar from './components/Sidebar';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import './style-pages/userprofile.css';

export default function UserProfile() {
    const auth = getAuth();
    const navigate = useNavigate();
    const [userGid, setUserGid] = useState();
    const [userInfo, setUserInfo] = useState({});
    const [userPosts, setUserPosts] = useState([]);
    const [dataExists, setDataExists] = useState(false);
    const [userPostsLength, setUserPostsLength] = useState(0);
    const [teamsFollowed, setTeamsFollowed] = useState(0);


    const getUserProfile = async () => {
        const queryParams = new URLSearchParams(window.location.search)
        const gid = queryParams.get("gid")
        setUserGid(gid);

          await axios.post("https://propicks-data-api.com/get-user-with-posts", {
              "user_gid": gid,
            })
              .then((response) => {
                console.log(response.data)
                setUserInfo(response.data.user_info);
                console.log(response.data.user_info.teams_followed.length)
                if(response.data.user_posts.post_data != undefined && response.data.user_posts.length != 0){
                    console.log("assigning value")
                    console.log(response.data.user_info)
                    setUserPosts(response.data.user_posts.post_data);
                    setUserPostsLength(response.data.user_posts.post_data.length);
                }
                if(response.data.user_info.teams_followed != undefined) {
                    setTeamsFollowed(response.data.user_info.teams_followed.length);
                }
                setDataExists(true);
              })
              .catch((err) => {
                console.log({ err: err });
              })
    };

    const likeMessage = (message_id) => {
        console.log(`liked message: ${message_id}`)
    }

    useEffect(() => {
        getUserProfile();
        console.log(userPosts)
    }, [])

    if(!dataExists){
        <div className='user-profile-content'>
            <Sidebar />
            <div className='main-content'>
                <p> Loading... </p>
            </div>
        </div>
    }

    if(dataExists && userPostsLength === 0){
        <div className='user-profile-content'>
            <Sidebar />
            <div className='main-content'>
                <button style={{height: '30px', width: '100px', marginBottom: '25px', marginTop: '-15px', padding: '5px'}} onClick={() => {navigate(-1)}}>{"< Back"}</button>
                <div className='user-attributes-section'>
                    <h3>{userInfo.name}</h3>
                </div>
                <div className='user-posts-section'>
                    <p> {userInfo.name}'s Posts: </p>
                    <p> User has no posts... </p>
                </div>
            </div>
        </div>
    }

    return (
        <div className='user-profile-content'>
            <Sidebar />
            <div className='main-content'>
                <button style={{height: '30px', width: '100px', marginBottom: '25px', marginTop: '-15px', padding: '5px'}} onClick={() => {navigate(-1)}}>{"< Back"}</button>
                <div className='profile-header-div'>
                    <h2> {userInfo.name} </h2>
                    <div className='counter-div'>
                        <h3> {userPostsLength} </h3>
                        <p> Posts </p>
                    </div>
                    <div className='counter-div'>
                        <h3> {teamsFollowed} </h3>
                        <p> Teams </p>
                    </div>
                </div>
                <div className='user-posts-section'>
                    <p> {userInfo.name}'s Posts: </p>
                    <div>
                        {
                            userPosts.map((message) => {
                                return(
                                    <div key={message[0]} className='user-feed-message'>
                                        <p>{message[1]}</p>
                                        <p>{message[3]}</p>
                                        <div>
                                            <button style={{height: '30px', width: '60px', marginBottom: '0px', marginTop: '-10px', padding: '0px'}} onClick={() => {likeMessage(message[0])}} >Like</button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}