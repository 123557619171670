import React from 'react';
import './style-pages/testchat.css';
import TopBar from './components/TopBar';

export default function TestChat() {
    return(
        <div className='test-chat-content' >
            <TopBar />
            <div className='test-chat-container' >
                <p>This is where the chat will be displayed</p>
                <p>With replies below it...</p>
            </div>
        </div>
    )
}