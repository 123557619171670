import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Process from './user-flow-pages/Process';
import MainDashboard from './dash-pages/MainDashboard';
import Profile from './dash-pages/Profile';
import PaymentPage from './user-flow-pages/PaymentPage';
import PayWall from './user-flow-pages/PayWall';
import PositiveEV from './dash-pages/PositiveEV';
import Arbitrage from './dash-pages/Arbitrage';
import BetScanner from './dash-pages/BetScanner';
import Plans from './dash-pages/Plans';
import ContactSupport from './user-flow-pages/ContactSupport';
import SignUp from './user-flow-pages/SignUp';
import SignIn from './user-flow-pages/SignIn';
import GeneralSocial from './dash-pages/GeneralSocial';
import DailyPickSocial from './dash-pages/DailyPickSocial';
import TeamsChat from './dash-pages/TeamsChat';
import TeamChatFeed from './dash-pages/TeamChatFeed';
import Settings from './dash-pages/Settings';
import UserProfile from './dash-pages/UserProfile';

function App() {
  return (
    <BrowserRouter className="router">
        <Routes>
          <Route index element={<MainDashboard />} />
          <Route path="/" element={<MainDashboard />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/process" element={<Process />} />
          <Route path="/pricing" element={<PayWall />} />
          <Route path="/pay" element={<PaymentPage />} />
          <Route path="/error" element={<ContactSupport/> } />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn /> } />
          <Route path="/profile" element={<Profile />} />
          <Route path="/general" element={<GeneralSocial />} />
          <Route path="/dailypick" element={<DailyPickSocial />} />
          <Route path="/teams" element={<TeamsChat />} />
          <Route path="/teamchat" element={<TeamChatFeed />} />
          <Route path="/positiveev" element={<PositiveEV />} />
          <Route path="/arbitrage" element={<Arbitrage />} />
          <Route path="/betscanner" element={<BetScanner />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/userProfile" element={<UserProfile />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
