import React from "react";
import Sidebar from "./components/Sidebar";
import { useNavigate } from "react-router-dom";
import "./style-pages/dailypicksocial.css";

export default function DailyPickSocial() {
    const navigate = useNavigate();

    return (
        <div className="daily-content">
            <Sidebar />
            <main className='main-content'>
                <h2>Daily Pick</h2>
                <p>Under Construction... Coming Soon!</p>
                <div className='button-container'>
                    <button onClick={() => (navigate('/dashboard'))} > Take Me Home! </button>
                </div>
            </main>
        </div>
    )
}