// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scanner-content {
    display: flex;
    height: 100vh;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 40px;
    background-color: #0d1117;
    color: #c9d1d9;
    width: 83%;
    margin-left: max(17%, 230px);
  }
  
  .main-content h2 {
    color: #58a6ff;
    margin-bottom: 20px;
  }

  .button-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .button-container button {
    padding: 10px 20px;
    background-color: #21262d;
    color: #c9d1d9;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button-container button:hover {
    background-color: #30363d;
  }

  .login-cta-div {
    width: 25%;
    text-align: center;
    margin-left: 37.5%;
  }
  `, "",{"version":3,"sources":["webpack://./src/dash-pages/style-pages/betscanner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,cAAc;IACd,UAAU;IACV,4BAA4B;EAC9B;;EAEA;IACE,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,SAAS;IACT,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,UAAU;IACV,kBAAkB;IAClB,kBAAkB;EACpB","sourcesContent":[".scanner-content {\n    display: flex;\n    height: 100vh;\n  }\n  \n  .main-content {\n    flex-grow: 1;\n    padding: 40px;\n    background-color: #0d1117;\n    color: #c9d1d9;\n    width: 83%;\n    margin-left: max(17%, 230px);\n  }\n  \n  .main-content h2 {\n    color: #58a6ff;\n    margin-bottom: 20px;\n  }\n\n  .button-container {\n    display: flex;\n    gap: 10px;\n    margin-bottom: 20px;\n  }\n  \n  .button-container button {\n    padding: 10px 20px;\n    background-color: #21262d;\n    color: #c9d1d9;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .button-container button:hover {\n    background-color: #30363d;\n  }\n\n  .login-cta-div {\n    width: 25%;\n    text-align: center;\n    margin-left: 37.5%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
