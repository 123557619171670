import fanduel from './data-assets/fanduel.png';
import draftkings from './data-assets/draftkings.png';
import betrivers from './data-assets/betrivers.png';
import betus from './data-assets/betus.png';
import bovada from './data-assets/bovada.png';
import mybookieag from './data-assets/mybookieag.png';
import betmgm from './data-assets/betmgm.png';
import lowvig from './data-assets/lowvig.png';

export const sportsbook_data = [
    {
        "book_code": "fanduel",
        "book_name": "Fanduel",
        "book_logo": fanduel,
        "promoter_link": "", // Still waiting to get the affiliate codes...
        "book_offer": "Bet $5+, Get $200 in Bonus Bets! Join Fanduel today to claim your Bonus Bets.",
        "states": [
            "arizona",
            "colorado",
            "connecticut",
            "washingtondc",
            "illinois",
            "indiana",
            "iowa",
            "kansas",
            "kentucky",
            "louisiana",
            "massachusetts",
            "maryland",
            "michigan",
            "mississippi",
            "northcarolina",
            "newjersey",
            "newyork",
            "nevada",
            "ohio",
            "pennsylvania",
            "tennessee",
            "virginia",
            "vermont",
            "washington",
            "westvirginia",
            "wyoming",
            "puertorico",
        ]
    },
    {
        "book_code": "draftkings",
        "book_name": "DraftKings",
        "book_logo": draftkings,
        "promoter_link": "",
        "book_offer": "Get $200 in Bonus Bets Plus up to $1,000 in Deposit Matches! Join DraftKings Today to Claim.",
        "states": [
            "arizona",
            "colorado",
            "connecticut",
            "washingtondc",
            "illinois",
            "indiana",
            "iowa",
            "kansas",
            "kentucky",
            "louisiana",
            "maine",
            "massachusetts",
            "maryland",
            "michigan",
            "mississippi",
            "northcarolina",
            "newhampshire",
            "newjersey",
            "newyork",
            "nevada",
            "ohio",
            "oregon",
            "pennsylvania",
            "tennessee",
            "virginia",
            "vermont",
            "washington",
            "westvirginia",
            "wyoming",
        ]
    },
    {
        "book_code": "betus",
        "book_name": "BetUS",
        "book_logo": betus,
        "promoter_link": "",
        "book_offer": "",
        "states": [
            "alabama",
            "alaska",
            "arizona",
            "arkansas",
            "california",
            "colorado",
            "connecticut",
            "delaware",
            "florida",
            "georgia",
            "hawaii",
            "idaho",
            "illinois",
            "indiana",
            "iowa",
            "kansas",
            "kentucky",
            "louisiana",
            "maine",
            "maryland",
            "massachusetts",
            "michigan",
            "minnesota",
            "mississippi",
            "missouri",
            "montana",
            "nebraska",
            "nevada",
            "newhampshire",
            "newjersey",
            "newmexico",
            "newyork",
            "northcarolina",
            "northdakota",
            "ohio",
            "oklahoma",
            "oregon",
            "pennsylvania",
            "rhodeisland",
            "southcarolina",
            "southdakota",
            "tennessee",
            "texas",
            "utah",
            "vermont",
            "virginia",
            "washington",
            "westvirginia",
            "wisconsin",
            "wyoming",
            "washingtondc",
            "puertorico",
        ]
    },
    {
        "book_code": "mybookieag",
        "book_name": "MyBookieAG",
        "book_logo": mybookieag,
        "promoter_link": "",
        "book_offer": "",
        "states": [
            "alabama",
            "alaska",
            "arizona",
            "arkansas",
            "california",
            "colorado",
            "connecticut",
            "delaware",
            "florida",
            "georgia",
            "hawaii",
            "idaho",
            "illinois",
            "indiana",
            "iowa",
            "kansas",
            "kentucky",
            "louisiana",
            "maine",
            "maryland",
            "massachusetts",
            "michigan",
            "minnesota",
            "mississippi",
            "missouri",
            "montana",
            "nebraska",
            "newhampshire",
            "newmexico",
            "northcarolina",
            "northdakota",
            "ohio",
            "oklahoma",
            "oregon",
            "rhodeisland",
            "southcarolina",
            "southdakota",
            "tennessee",
            "texas",
            "utah",
            "vermont",
            "virginia",
            "washington",
            "westvirginia",
            "wisconsin",
            "wyoming",
            "washingtondc",
            "puertorico",
        ]
    },
    {
        "book_code": "betmgm",
        "book_name": "BetMGM",
        "book_logo": betmgm,
        "promoter_link": "",
        "book_offer": "",
        "states": [
            "arizona",
            "colorado",
            "washingtondc",
            "illinois",
            "indiana",
            "iowa",
            "kansas",
            "kentucky",
            "louisiana",
            "maryland",
            "massachusetts",
            "michigan",
            "mississippi",
            "newjersey",
            "nevada",
            "newyork",
            "northcarolina",
            "ohio",
            "oregon",
            "pennsylvania",
            "puertorico",
            "tennessee",
            "virginia",
            "westvirginia",
            "wyoming",
        ]
    },
    {
        "book_code": "betrivers",
        "book_name": "BetRivers",
        "book_logo": betrivers,
        "promoter_link": "",
        "book_offer": "",
        "states": [
            "arizona",
            "colorado",
            "delaware",
            "illinois",
            "indiana",
            "iowa",
            "louisiana",
            "maryland",
            "michigan",
            "newjersey",
            "newyork",
            "ohio",
            "pennsylvania",
            "virginia",
            "westvirginia",
        ]
    },
    {
        "book_code": "lowvig",
        "book_name": "LowVig",
        "book_logo": lowvig,
        "promoter_link": "",
        "book_offer": "",
        "states": [
            "alabama",
            "alaska",
            "arizona",
            "arkansas",
            "california",
            "colorado",
            "connecticut",
            "delaware",
            "florida",
            "georgia",
            "hawaii",
            "idaho",
            "illinois",
            "indiana",
            "iowa",
            "kansas",
            "kentucky",
            "louisiana",
            "maine",
            "maryland",
            "massachusetts",
            "michigan",
            "minnesota",
            "mississippi",
            "missouri",
            "montana",
            "nebraska",
            "nevada",
            "newhampshire",
            "newjersey",
            "newmexico",
            "newyork",
            "northcarolina",
            "northdakota",
            "ohio",
            "oklahoma",
            "oregon",
            "pennsylvania",
            "rhodeisland",
            "southcarolina",
            "southdakota",
            "tennessee",
            "texas",
            "utah",
            "vermont",
            "virginia",
            "washington",
            "westvirginia",
            "wisconsin",
            "wyoming",
            "washingtondc",
            "puertorico",
        ]
    },
    {
        "book_code": "bovada",
        "book_name": "Bovada",
        "book_logo": bovada,
        "promoter_link": "",
        "book_offer": "",
        "states": [
            "alabama",
            "alaska",
            "arkansas",
            "california",
            "georgia",
            "hawaii",
            "idaho",
            "illinois",
            "indiana",
            "iowa",
            "kentucky",
            "minnesota",
            "mississippi",
            "missouri",
            "montana",
            "nebraska",
            "new mexico",
            "north carolina",
            "north dakota",
            "oregon",
            "rhode island",
            "south carolina",
            "south dakota",
            "texas",
            "utah",
            "vermont",
            "virginia",
            "washington",
            "wisconsin",
            "wyoming"
        ]
    },
]