import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from './components/TopBar';
import './style-pages/testaccount.css';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import axios from 'axios';

import background from './assets/profilebackground.png';
import pfp from './assets/user_pfp.jpg';
import like from './assets/heart.png';
import comment from './assets/comment.png';
import share from './assets/share-06.png';
import bookmark from './assets/bookmark.png';
import nochats from './assets/text-bubble.png';
import bets from './assets/coins-dollar.png';
import team_chats from './assets/thread.png';

export default function TestAccount() {
    const navigate = useNavigate();
    const auth = getAuth();
    const standard = "https://firebasestorage.googleapis.com/v0/b/propicksio.firebasestorage.app/o/defaultProfilePicture%2Fuser_pfp.jpg?alt=media&token=71272afc-3c7b-4d62-8c0d-2c2357be4207";

    const [selectedTab, setSelectedTab] = useState("Posts");
    const [mainBlurred, setMainBlurred] = useState(false);
    const [signUpCTA, setSignUpCTA] = useState(false);
    const [userExists, setUserExists] = useState(false);
    const [user, setUser] = useState();
    const [postsExist, setPostsExist] = useState(true);
    const [repliesExist, setRepliesExist] = useState(false);
    const [text, setText] = useState("");
    const [characterCount, setCharacterCount] = useState(0);
    const [postStarted, setPostStarted] = useState(false);
    const [teamFinderOpen, setTeamFinderOpen] = useState(false);
    const [betFinderOpen, setBetFinderOpen] = useState(false);
    const [userLikes, setUserLikes] = useState();
    const [userLikesExist, setUserLikesExist] = useState(false);
    const [userSaves, setUserSaves] = useState();
    const [userSavesExist, setUserSavesExist] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pfp, setPfp] = useState(standard);
    
    const post_data = [
        "post content 1",
        "post content 2",
        "post content 3",
        "post content 4",
        "post content 5",
        "post content 6"
    ]

    const reply_data = [];

    const tab_names = ["Posts", "Replies", "Likes", "Saves"];

    const checkUserStatus = () => {
        if(!userExists){
            setMainBlurred(true);
            setSignUpCTA(true);
        }
    }

    const updateText = (e) => {
        setText(e.target.value);
        setCharacterCount(e.target.value.length);

        if(e.target.value.length > 0){
            setPostStarted(true);
        } else {
            setPostStarted(false);
        }
    }

    const createPost = () => {
        if(characterCount > 300){
            alert("Cannot create post, must have only 300 characters or less.")
        } else {
            setText("");
            setCharacterCount(0);
        }
    }

    const fetchUserProfile = async (gid) => {
        const session = sessionStorage.getItem("user-session-data");
        let user_session_data = null;

        try {
            user_session_data = session ? JSON.parse(session) : null;
        } catch (err) {
            sessionStorage.removeItem("user-session-data");
        }

        if(user_session_data != null){
            setUser(user_session_data);
            setPfp(user_session_data.pfp);
            setUserExists(true);    
            setLoading(false);
        } else {
            const profile_response = await axios.post("https://propicks-data-api.com/v1/get-user", {
                gid: gid,
            })

            console.log(profile_response.data);

            if(profile_response.status == 200){
                setUser(profile_response.data.user);
                setLoading(false);
                setUserExists(true);

                sessionStorage.setItem('user-session-data', JSON.stringify(profile_response.data.user));

                if(profile_response.data.user){
                    setPfp(profile_response.data.user.pfp);
                }
            } else {
                console.log({ err: "Call not succesful "})
            }
        }
    }

    useEffect(() => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    fetchUserProfile(user.uid);
                } else {
                    console.log("No user signed in...")
                }
            })
    }, [])

    if(loading){
        return(
            <div className='test-profile-content' >
                <TopBar />
                <div className='test-profile-container' >
                    <p>Loading...</p>
                </div>
            </div>
        )
    }

    return(
        <div className='test-profile-content' >
            <TopBar />
            <div className='test-profile-container' >
                <div className='test-profile-top' >
                    <div className='profile-top-backdrop' >
                        <img src={background} />
                    </div>
                    <div className='profile-top-content' >
                        <div className='name-container' >
                            <img src={pfp} height="84" width="84" />
                            <div className='username-container' >
                                <p className='profile-username' >{user.name}</p>
                            </div>
                        </div>
                        <p className='profile-description'>{user.bio}</p>
                    </div>
                </div>
                <div className='test-profile-mid' >
                    <div className="forum-menu-container">
                            {
                                tab_names.map((tab) => (
                                    <div
                                        key={tab}
                                        onClick={() => setSelectedTab(tab)}
                                        className={`forum-menu-button ${selectedTab === tab ? "current" : ""}`}
                                    >
                                        {tab}
                                    </div>
                                ))
                            }
                    </div>
                    <div className="feed-content">
                        {selectedTab === "Posts" && 
                            <div className='main-feed-content' >
                                <div className='create-post-container'>
                                    <div className='create-post-main' >
                                        <img className='create-post-pfp' src={pfp} height="28" width="28" />
                                        <textarea className='create-post-input no-detail' value={text} type="text" onChange={(e) => updateText(e)} placeholder='Write a post!'/>
                                    </div>
                                    <div className='create-post-buttons' >
                                        <div className='create-post-buttons-left-container'>
                                            <button className='create-post-left-button' onClick={() => {
                                                setBetFinderOpen(true);
                                                setTeamFinderOpen(false);
                                                setSignUpCTA(false);
                                                setMainBlurred(!mainBlurred);
                                            }}>
                                                <img src={bets} height="16" width="16" />
                                                <p className='create-post-left-button-text' >Bets</p>
                                            </button>
                                            <button className='create-post-left-button' onClick={() => {
                                                setTeamFinderOpen(true);
                                                setBetFinderOpen(false);
                                                setSignUpCTA(false);
                                                setMainBlurred(!mainBlurred);
                                            }}>
                                                <img src={team_chats} height="16" width="16" />
                                                <p className='create-post-left-button-text' >Teams</p>
                                            </button>
                                        </div>
                                        <div className='create-post-buttons-right-container'>
                                            <div className='create-post-right-button' disabled={true}>
                                                <p className='character-count' >
                                                    {characterCount}/300    
                                                </p>
                                            </div>
                                            <div className='create-post-right-button' >
                                                <button className={`post-button ${postStarted ? "" : "not-started"}`} onClick={() => createPost()}>Post</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='social-feed'>
                                    {
                                        postsExist ? (
                                            <>
                                                {
                                                    post_data.map((post) => {
                                                        return(
                                                            <div className='user-post-container'>
                                                                <div className='post-header-container'>
                                                                    <img src={pfp} width="32" height="32" />
                                                                    <div className='post-user-info'>
                                                                        <p className='post-username-dash' onClick={() => {navigate('/test-profile')}}>Username</p>
                                                                        <p><span>in</span> Team Name</p>
                                                                    </div>
                                                                </div>
                                                                <div className='post-content'>
                                                                    {post}
                                                                </div>
                                                                <div className='post-buttons-container'>
                                                                    <div className='interact-button' >
                                                                        <img src={like} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>2.5k</p>
                                                                    </div>
                                                                    <div className='interact-button' >
                                                                        <img src={comment} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>247</p>
                                                                    </div>
                                                                    <div className='interact-button' >
                                                                        <img src={share} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>Share</p>
                                                                    </div>
                                                                    <div className='interact-button' >
                                                                        <img src={bookmark} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>Save</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                        ) : (
                                            <>
                                                <div className='no-posts-container' >
                                                    <img src={nochats} height="96" width="96" />
                                                    <p className='no-posts-title' >No Posts Yet? Let's Change That!</p>
                                                    <p className='no-posts-subtitle' >Share your thoughts, insights, or game-day predictions and get the conversation started.</p>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        }
                        {selectedTab === "Replies" && 
                            <div className='main-feed-content' >
                                <div className='social-feed' >
                                    {
                                        repliesExist ? (
                                            <>
                                                {
                                                    reply_data.map((post) => {
                                                        return(
                                                            <div className='user-post-container'>
                                                                <div className='post-header-container'>
                                                                    <img src={pfp} width="32" height="32" />
                                                                    <div className='post-user-info'>
                                                                        <p className='post-username-dash' onClick={() => {navigate('/test-profile')}}>Username</p>
                                                                        <p><span>in</span> Team Name</p>
                                                                    </div>
                                                                </div>
                                                                <div className='post-content'>
                                                                    {post}
                                                                </div>
                                                                <div className='post-buttons-container'>
                                                                    <div className='interact-button' >
                                                                        <img src={like} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>2.5k</p>
                                                                    </div>
                                                                    <div className='interact-button' >
                                                                        <img src={comment} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>247</p>
                                                                    </div>
                                                                    <div className='interact-button' >
                                                                        <img src={share} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>Share</p>
                                                                    </div>
                                                                    <div className='interact-button' >
                                                                        <img src={bookmark} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>Save</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                        ) : (
                                            <>
                                                <div className='no-posts-container' >
                                                    <img src={nochats} height="96" width="96" />
                                                    <p className='no-posts-title' >No Replies Yet? Join the Conversation!</p>
                                                    <p className='no-posts-subtitle' >Check out new and trending posts on the <span onClick={() => {navigate('/test-dashboard')}}>Home Dashboard</span>.</p>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        }
                        {selectedTab === "Likes" && 
                            <div className='main-feed-content' >
                                <div className='social-feed' >
                                    {
                                        userLikesExist ? (
                                            <>
                                            
                                            </>
                                        ) : (
                                            <>
                                                <div className='no-posts-container' >
                                                    <img src={nochats} height="96" width="96" />
                                                    <p className='no-posts-title' >No Likes Yet? Check out some Posts!</p>
                                                    <p className='no-posts-subtitle' >Check out new and trending posts on the <span onClick={() => {navigate('/test-dashboard')}}>Home Dashboard</span>.</p>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        }
                        {selectedTab === "Saves" && 
                            <div className='main-feed-content' >
                                <div className='social-feed' >
                                    {
                                        userSavesExist ? (
                                            <>
                                            
                                            </>
                                        ) : (
                                            <>
                                                <div className='no-posts-container' >
                                                    <img src={nochats} height="96" width="96" />
                                                    <p className='no-posts-title' >No Saves Yet? Check out some Posts!</p>
                                                    <p className='no-posts-subtitle' >Check out new and trending posts on the <span onClick={() => {navigate('/test-dashboard')}}>Home Dashboard</span>.</p>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}