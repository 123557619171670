import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style-pages/topbar.css';

import logo from '../assets/logo2.png';

export default function TopBar() {
    const navigate = useNavigate();

    return(
        <div className='topbar-content-signup' >
            <div className='logo-container' onClick={() => navigate("/")}>
                <img src={logo} height="24" width="24" />
                <h4 className='propicks-title'>ProPicks</h4>
            </div>
        </div>
    )
}