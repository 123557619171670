import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from './components/TopBar';
import './style-pages/testprofile.css';
import { onAuthStateChanged, getAuth } from 'firebase/auth';

import background from './assets/profilebackground.png';
import pfp from './assets/user_pfp.jpg';
import like from './assets/heart.png';
import comment from './assets/comment.png';
import share from './assets/share-06.png';
import bookmark from './assets/bookmark.png';
import nochats from './assets/text-bubble.png';
import bets from './assets/coins-dollar.png';
import team_chats from './assets/thread.png';

export default function TestProfile() {
    const navigate = useNavigate();
    const auth = getAuth();

    const [selectedTab, setSelectedTab] = useState("Posts");
    const [mainBlurred, setMainBlurred] = useState(false);
    const [signUpCTA, setSignUpCTA] = useState(false);
    const [userExists, setUserExists] = useState(false);
    const [user, setUser] = useState();
    const [postsExist, setPostsExist] = useState(false);
    const [repliesExist, setRepliesExist] = useState(false);
    const [text, setText] = useState("");
    const [characterCount, setCharacterCount] = useState(0);
    const [postStarted, setPostStarted] = useState(false);
    const [teamFinderOpen, setTeamFinderOpen] = useState(false);
    const [betFinderOpen, setBetFinderOpen] = useState(false);
    
    const post_data = [
        "post content 1",
        "post content 2",
        "post content 3",
        "post content 4",
        "post content 5",
        "post content 6"
    ]

    const reply_data = [];

    const tab_names = ["Posts", "Replies"];

    const checkUserStatus = () => {
        if(!userExists){
            setMainBlurred(true);
            setSignUpCTA(true);
        }
    }

    const updateText = (e) => {
        setText(e.target.value);
        setCharacterCount(e.target.value.length);

        if(e.target.value.length > 0){
            setPostStarted(true);
        } else {
            setPostStarted(false);
        }
    }

    useEffect(() => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    setUser(user);
                    setUserExists(true);
                } else {
                    console.log("No user signed in...")
                }
            })
    }, [])

    return(
        <div className='test-profile-content' >
            <TopBar />
            <div className='test-profile-container' >
                <div className='test-profile-top' >
                    <div className='profile-top-backdrop' >
                        <img src={background} />
                    </div>
                    <div className='profile-top-content' >
                        <div className='name-container' >
                            <img src={pfp} height="84" width="84" />
                            <div className='username-container' >
                                <p className='profile-username' >Username</p>
                            </div>
                        </div>
                        <p className='profile-description'>This is where the user's profile bio goes!</p>
                    </div>
                </div>
                <div className='test-profile-mid' >
                    <div className="forum-menu-container">
                            {
                                tab_names.map((tab) => (
                                    <div
                                        key={tab}
                                        onClick={() => setSelectedTab(tab)}
                                        className={`forum-menu-button ${selectedTab === tab ? "current" : ""}`}
                                    >
                                        {tab}
                                    </div>
                                ))
                            }
                    </div>
                    <div className="feed-content">
                        {selectedTab === "Posts" && 
                            <div className='main-feed-content' >
                                <div className='social-feed'>
                                    {
                                        postsExist ? (
                                            <>
                                                {
                                                    post_data.map((post) => {
                                                        return(
                                                            <div className='user-post-container'>
                                                                <div className='post-header-container'>
                                                                    <img src={pfp} width="32" height="32" />
                                                                    <div className='post-user-info'>
                                                                        <p className='post-username-dash' onClick={() => {navigate('/test-profile')}}>Username</p>
                                                                        <p><span>in</span> Team Name</p>
                                                                    </div>
                                                                </div>
                                                                <div className='post-content'>
                                                                    {post}
                                                                </div>
                                                                <div className='post-buttons-container'>
                                                                    <div className='interact-button' >
                                                                        <img src={like} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>2.5k</p>
                                                                    </div>
                                                                    <div className='interact-button' >
                                                                        <img src={comment} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>247</p>
                                                                    </div>
                                                                    <div className='interact-button' >
                                                                        <img src={share} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>Share</p>
                                                                    </div>
                                                                    <div className='interact-button' >
                                                                        <img src={bookmark} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>Save</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                        ) : (
                                            <>
                                                <div className='no-posts-container' >
                                                    <img src={nochats} height="96" width="96" />
                                                    <p className='no-posts-title' >No Posts Here!</p>
                                                    <p className='no-posts-subtitle' >Hmm... Looks like this user has no posts.</p>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        }
                        {selectedTab === "Replies" && 
                            <div className='main-feed-content' >
                                <div className='social-feed' >
                                    {
                                        repliesExist ? (
                                            <>
                                                {
                                                    reply_data.map((post) => {
                                                        return(
                                                            <div className='user-post-container'>
                                                                <div className='post-header-container'>
                                                                    <img src={pfp} width="32" height="32" />
                                                                    <div className='post-user-info'>
                                                                        <p className='post-username-dash' onClick={() => {navigate('/test-profile')}}>Username</p>
                                                                        <p><span>in</span> Team Name</p>
                                                                    </div>
                                                                </div>
                                                                <div className='post-content'>
                                                                    {post}
                                                                </div>
                                                                <div className='post-buttons-container'>
                                                                    <div className='interact-button' >
                                                                        <img src={like} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>2.5k</p>
                                                                    </div>
                                                                    <div className='interact-button' >
                                                                        <img src={comment} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>247</p>
                                                                    </div>
                                                                    <div className='interact-button' >
                                                                        <img src={share} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>Share</p>
                                                                    </div>
                                                                    <div className='interact-button' >
                                                                        <img src={bookmark} height="16" width="16" onClick={() => {checkUserStatus()}} />
                                                                        <p>Save</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                        ) : (
                                            <>
                                                <div className='no-posts-container' >
                                                    <img src={nochats} height="96" width="96" />
                                                    <p className='no-posts-title' >No Replies Here!</p>
                                                    <p className='no-posts-subtitle' >This user doesn't have any replies.</p>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}