// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar-funnel-content {
    width: calc(100% - 20%);
    height: 24px;
    padding: 14px 10% 18px 10%;
    top: 0px;
    left: 0px;
    background-color: var(--brand-shade-600);
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: 0;
}

.step-container {
    height: 28px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.step-visual-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
}

.step-visual {
    height: 4px;
    width: 48px;
    border: none;
    border-radius: 12px;
    background-color: var(--brand-shade-200);
}

.complete {
    background-color: var(--accent-shade-500);
}
`, "",{"version":3,"sources":["webpack://./src/user-flow-pages/components/style-pages/topbarfunnel.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,0BAA0B;IAC1B,QAAQ;IACR,SAAS;IACT,wCAAwC;IACxC,gCAAgC;IAChC,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,MAAM;AACV;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,wCAAwC;AAC5C;;AAEA;IACI,yCAAyC;AAC7C","sourcesContent":[".topbar-funnel-content {\n    width: calc(100% - 20%);\n    height: 24px;\n    padding: 14px 10% 18px 10%;\n    top: 0px;\n    left: 0px;\n    background-color: var(--brand-shade-600);\n    font-family: 'Inter', sans-serif;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    top: 0;\n}\n\n.step-container {\n    height: 28px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    gap: 12px;\n}\n\n.step-visual-container {\n    display: flex;\n    flex-direction: row;\n    gap: 6px;\n    align-items: center;\n}\n\n.step-visual {\n    height: 4px;\n    width: 48px;\n    border: none;\n    border-radius: 12px;\n    background-color: var(--brand-shade-200);\n}\n\n.complete {\n    background-color: var(--accent-shade-500);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
