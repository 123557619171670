import React, { useState } from 'react';
import './style-pages/testsignin.css';
import TopBar from './components/TopBar';

import logo from './assets/logo.png';
import mail from './assets/mail.png';
import key from './assets/key.png';
import eye from './assets/eye.png';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';

export default function TestSignIn() {
    const navigate = useNavigate();
    const auth = getAuth();

    const [passwordView, setPasswordView] = useState("password");
    const [email, setEmail] = useState();
    const [emailLength, setEmailLength] = useState(0);
    const [password, setPassword] = useState();
    const [passwordLength, setPasswordLength] = useState(0);
    
    const togglePassView = () => {
        if(passwordView == "password"){
            setPasswordView("text")
        } else {
            setPasswordView("password")
        }
    }

    const updatePass = (e) => {
        setPassword(e.target.value);
        setPasswordLength(e.target.value.length);
    }

    const updateEmail = (e) => {
        setEmail(e.target.value);
        setEmailLength(e.target.value.length);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          signIn();
        }
      };

    const signIn = () => {
        if (emailLength == 0 || passwordLength == 0) {
            alert("Please enter email and password.");
        } else {
            signInWithEmailAndPassword(auth, email, password)
                .then(() => {
                    navigate("/") 
                })
                .catch((err) => {
                    console.log({ err: err.message });
                }) 
        }
    }

    return (
        <div className='test-signin-content'>
            <TopBar />
            <div className='signin-container'>
                <div className='header-group' >
                    <img src={logo} height="60" width="60" />
                    <p className='header-title' >Back for More Wins? Let's Do This!</p>
                    <p className='header-subtitle' >Log in and get back in the game - your next big pick awaits!</p>
                </div>
                <div className='email-group'>
                    <p className='email-title'>Email Address</p>
                    <div className='email-input-group'>
                        <img src={mail} height="16" width="18" />
                        <textarea className='email-input-textarea' placeholder="Email Address" onChange={(e) => {updateEmail(e)}}></textarea>
                    </div>
                </div>
                <div className='password-group'>
                    <p className='password-title'>Password</p>
                    <div className='password-input-group'>
                        <img src={key} height="16" width="18" />
                        <input className='password-input-textarea' type={passwordView} placeholder="Password" onChange={(e) => {updatePass(e)}} onKeyDown={handleKeyDown} />
                        <img src={eye} height="20" width="20" onClick={() => {togglePassView()}}/>
                    </div>
                </div>
                <div className='bottom-group'>
                    <button className='signin-button' onClick={() => {signIn()}} >Log In</button>
                    <p className='returning-title' >Don't have an account? <span onClick={() => {navigate("/test-signup")}}>Click here!</span></p>
                </div>
            </div>
        </div>
    )
}