import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./style-pages/plans.css";

export default function PayWall() {
    const navigate = useNavigate();

    const handleLogin = (args) => {
        return "nothing"
    }

    const handlePayment = async (stripe_url) => {
        // this is where you send the user to the stripe payment page
        // Get the stripe link and send the user there
        // The main goal is how to find out which user paid and update it in their database row

        // according to openai, this is where you have to use webhooks to track the user through the payment stage
    }

    // The new direction is to just take the payment flow from array assistant and implement it into the current site and api... some of that code already exists you just
    // need to finish it.

    return (
        <div className='pricing-page'>
            <div className="pricing-content-paywall">
                <h1>Choose your Plan</h1>
                <div className="pricing-cards">
                    <div className="pricing-card">
                        <h2>Basic</h2>
                        <div className="price">
                            <span className="currency">$</span>19.99
                            <span className="per-month">Monthly</span>
                        </div>
                        <p>Beginner Tools</p>
                        <button onClick={() => {navigate("/dashboard")}}>Start Free Trial</button>
                        <ul>
                            <li>Positive EV Early Access</li>
                            <li>Arbitrage Early Access</li>
                            <li>Sportsbook Screener</li>
                            <li>Social Community Early Access</li>
                            <li>Betting Calculators</li>
                        </ul>
                    </div>
                    <div className="pricing-card">
                        <h2>Pro</h2>
                        <div className="price">
                            <span className="currency"></span>Coming Soon
                            <span className="per-month">In 2025</span>
                        </div>
                        <p>Advanced Tools</p>
                        <button onClick={() => {handleLogin("/dashboard")}}>Try Basic Plan</button>
                        <ul>
                            <li>All Basic Tools</li>
                            <li>Push Notifications</li>
                            <li>Exclusive Discord Access</li>
                            <li>Positive EV</li>
                            <li>Arbitrage Betting</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
