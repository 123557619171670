import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBfOcee5thzuAijmu3haJmjs93IfNFqqwc",
    authDomain: "propicksio.firebaseapp.com",
    projectId: "propicksio",
    storageBucket: "propicksio.appspot.com",
    messagingSenderId: "1087004002359",
    appId: "1:1087004002359:web:324594a17f085add2c57b2",
    measurementId: "G-6CFJ4VWJGF"
}

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();