import React, { useState, useReducer, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './components/Sidebar';
import './style-pages/pev.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default function PositiveEV() {
    const navigate = useNavigate();
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const session = sessionStorage.getItem('accountData');

    const [betData, setBetData] = useState();
    const [dataExists, setExists] = useState(false);

    const auth = getAuth();

    const [authenticated, setAuthentication] = useState(false);
    const [user, setUser] = useState({});

    const propogateBetData = async () => {
        await axios.get("https://propicks-data-api.com/pev-data")
            .then((response) => {
                setBetData(response.data.bets);
                if(response.data.bets != undefined || response.data.bets != null){
                    setExists(true);  
                }
                
            })
            .catch((e) => {
                console.log("There was an error.");
                console.log({ message: e.message });
            })
    };

    const sortByEV = () => {
        const bet_data = betData;

        function compareValue(a, b) {
            if (a.positive_play_percentage < b.positive_play_percentage) { return 1; }
            if (a.positive_play_percentage > b.positive_play_percentage) { return -1; }
            return 0;
        }
          
        bet_data.sort(compareValue);
        setBetData(bet_data);
        forceUpdate(); // Get rid of this at some point
    };

    const getUserInfo = useCallback(async (gid) => {
        if (session != undefined) {
            setUser(JSON.parse(session));
        } else {
            await axios.post("https://propicks-data-api.com/get-user", {
                gid: gid,
              })
                .then((response) => {
                  setUser(response.data.postgres_response);
                  sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
                })
                .catch((err) => {
                  console.log({ err: err });
                })
        }
    });

    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if(user){
          setAuthentication(true);
          getUserInfo(user.uid);
        } else {
          console.log("no user :(");
        }
      })
      propogateBetData();
    }, [])

    if(!authenticated){
        return(
          <div className='pev-content'>
            <Sidebar />
            <div className='main-content'>
              <button>Sign In</button>
            </div>
          </div>
        )
    }

    return (
        <div className='pev-content'>
            <Sidebar />
            <main className='main-content'>
                <div className='title-container' >
                    <h2 className='title-pev'>Positive EV Bets</h2>
                    <div className='button-container'>
                        <button onClick={() => {propogateBetData()}} > {dataExists ? ("Update Data") : ("Get Data")} </button>
                        <button onClick={() => {sortByEV()}} > Sort By EV </button>
                    </div>
                </div>
                {dataExists ? (
                    <div className='table-container'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Game</th>
                                    <th>Expected Value</th>
                                    <th>Book</th>
                                    <th>Price</th>
                                    <th>Probability</th>
                                    <th>Direction</th>
                                </tr>
                            </thead>
                            <tbody>
                                {betData.map((bet) => {
                                    let name1, name2, game = bet.event;

                                    let index = game.indexOf(" vs ");
                                    name1 = game.substring(0, index);
                                    name2 = game.substring(index + 4, game.length);

                                    return(
                                        <tr key={bet.uid}>
                                            <td>
                                                <div className='cell-content'>
                                                    <p>{name1}</p>
                                                    <p>{name2}</p>
                                                </div>
                                            </td>
                                            <td>{Number(bet.positive_play_percentage).toFixed(2)}%</td>
                                            <td>{bet.book}</td>
                                            <td>{bet.positive_play_price}</td>
                                            <td>{bet.no_vig_probability}%</td>
                                            <td>{bet.positive_play_name}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div>No Data Loaded Yet</div>
                )}
            </main>
        </div>
    )
}
