import React from 'react'
import SignUp from './SignUp'

export default function SignIn() {
  return (
    <>
        <SignUp sign_obj={{
            signin: true,
        }} />
    </>
  )
}
