import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import "./style-pages/paymentpage.css";
import "./PaymentContainer.js";

export default function PaymentPage() {
    const navigate = useNavigate();

    const { user, error, isAuthenticated } = useAuth0();
    const [pageError, setPageError] = useState();

    useEffect(() => {
        const error = new URLSearchParams(window.location.search).get(
            'error'
        )
        setPageError(error);
    })

    /*
    return (
        <div id="personalCheckoutDiv">
            { isAuthenticated ? (
                <div style={{ textAlign: "center" }} >
                    <h1> Checkout </h1>
                    <div>
                        <StripeContainer type="0" trial={false}/>
                    </div>
                </div>
            ) : (navigate("/dashboard"))}
            <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet"></link>
        </div>
    )
        */

    return(
        <div>
            Payment page
            <button onClick={() => {navigate("/dashboard")}}> Purchase </button>
        </div>
    )
}
