import React, { useState, useEffect, useCallback } from 'react';
import { json, useNavigate } from 'react-router-dom';
import './style-pages/dashboard.css';
import Sidebar from './components/Sidebar';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import GameModule from './components/gamemodule';

export default function MainDashboard() {
    const navigate = useNavigate();
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');

    const [authenticated, setAuthentication] = useState(false);
    const [user, setUser] = useState({});

    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if(user){
          setAuthentication(true);
          getUserInfo(user.uid);
        } else {
          console.log("no user :(");
        }
      })
    }, [])

    const getUserInfo = useCallback(async (gid) => {
      if (session != null) {
        setUser(JSON.parse(session));
      } else {
        console.log("session is null on main")
        await axios.post("https://propicks-data-api.com/get-user", {
            gid: gid,
          })
            .then((response) => {
              setUser(response.data.postgres_response);
              sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
            })
            .catch((err) => {
              console.log({ err: err });
            })
      }
    });

    if(authenticated){
        return (
            <div className="dashboard">
              <Sidebar />
              <main className="main-dashboard-content">
                <div className="modules">
                  <div className="module left">
                    <div className="account-module">
                      <h2 className='dashboard-header'>Welcome {user.name}</h2>
                    </div>
                    <div className="news-module">
                      <h3 className='news-header'>Sports News</h3>
                    </div>
                  </div>
                  <div className="module right">
                    <div className="games-module">
                      <GameModule />
                    </div>
                  </div>
                </div>
                <div className='lower-modules'>
                    <div className='module lower-left'>
                        <div className='test-mod-1'>
                            <p>
                                Coming Soon!
                            </p>
                        </div>
                    </div>
                    <div className='module lower-right'>
                        <div className='test-mod-2'>
                            <p>
                                Under Construction...
                            </p>
                        </div>
                    </div>
                </div>
              </main>
            </div>
          );
    }

    if(!authenticated){
      return(
        <div className="dashboard">
            <Sidebar />
            <main className="main-dashboard-content">
                <div className="modules">
                    <div className="module left">
                        <div className='welcome-module'>
                            <h2 className='dashboard-header'>Welcome to Propicks</h2>
                            <button className='signin-dash-button' onClick={() => {navigate('/signin')}}>Sign In</button>
                        </div>
                        <div className="news-module">
                            <h3 className='news-header'>Sports News</h3>
                        </div>
                    </div>
                    <div className="module right">
                        <div className="games-module">
                            <GameModule />
                        </div>
                    </div>
                </div>
                <div className='lower-modules'>
                    <div className='module left'>
                        <div className='test-mod-1'>
                            <p>
                                Coming Soon!
                            </p>
                        </div>
                    </div>
                    <div className='module right'>
                        <div className='test-mod-2'>
                            <p>
                                Under Construction...
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
      )
    }
}