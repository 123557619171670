import React, { useCallback } from 'react';
import { useReducer, useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from './components/Sidebar';
import './style-pages/arbitrage.css';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

export default function Arbitrage() {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const session = sessionStorage.getItem('accountData');
    const auth = getAuth();
    const navigate = useNavigate();

    const [betData, setBetData] = useState();
    const [previewData, setPreviewData] = useState();
    const [dataExists, setExists] = useState(false);
    const [previewExists, setPreviewExists] = useState(false);
    const [authenticated, setAuthentication] = useState(false);
    const [user, setUser] = useState({});
    const [subscribed, setSubscribed] = useState(false);

    const propogateBetData = async () => {
        await axios.get("https://propicks-data-api.com/arb-data")
            .then((response) => {
                setBetData(response.data.bets);
                if(response.data.bets != undefined || response.data.bets != null){
                    setExists(true);
                }
                
            })
            .catch((e) => {
                console.log("There was an error.");
                console.log({ message: e.message });
            })
    }

    const propogatePreviewData = async () => {
        await axios.get("https://propicks-data-api.com/arb-preview")
            .then((response) => {
                console.log(response.data.bets)
                setPreviewData(response.data.bets)
                if(response.data.bets != undefined || response.data.bets != null){
                    setPreviewExists(true)
                }
            })
            .catch((err) => {
                console.log("There was an error: ", err.message )
            })
    }

    const sortByPercent = () => {
        const bet_data = betData;

        function compareValue(a, b) {
            if (a.arbitrage_percentage < b.arbitrage_percentage) { return 1; }
            if (a.arbitrage_percentage > b.arbitrage_percentage) { return -1; }
            return 0;
        }
          
        bet_data.sort(compareValue);
        setBetData(bet_data);
        forceUpdate();
    }

    const getUserInfo = useCallback(async (gid) => {
        if (session != undefined) {
            setUser(JSON.parse(session));
        } else {
            await axios.post("https://propicks-data-api.com/get-user", {
                gid: gid,
              })
                .then((response) => {
                  setUser(response.data.postgres_response);
                  sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
                })
                .catch((err) => {
                  console.log({ err: err });
                })
        }
    });

    const checkSubscription = () => {
        if(user.tier == 1 || user.tier == 2){
            setSubscribed(true);
        }
    }

    useEffect(() => {
        let user_conditional = false;

        onAuthStateChanged(auth, (user) => {
            if(user){
                setAuthentication(true);
                getUserInfo(user.uid);
                user_conditional = true;
            } else {
                console.log("Getting preview...");
            }
            })

        if(user_conditional){
            propogateBetData();
        } else {
            propogatePreviewData();
        }

        checkSubscription();
    }, [])

    if(!authenticated){
        return(
          <div className='arbitrage-content'>
            <Sidebar />
            <div className='main-content'>
                <h3 className='arb-preview-header' > Arbitrage bets: </h3>
                {previewExists ? (
                    <div className='table-container'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th>Arb Play</th>
                                    <th>Book</th>
                                    <th>Price</th>
                                    <th>Arb %</th>
                                    <th>Commence Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                        previewData.map((bet) => {
                                            let { home_team, away_team } = bet;
                                            let { positive_play_name, negative_play_name } = bet;
                                            let { positive_play_book, negative_play_book } = bet;
                                            let { positive_play_price, negative_play_price } = bet;
                                            let { arbitrage_percentage, commence_time } = bet;

                                            const commence_date = new Date(commence_time)
                                            const options = {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                timeZone: 'America/New_York',
                                                timeZoneName: 'short'
                                            };
                                            
                                            const formatted_commence_time = new Intl.DateTimeFormat('en-US', options).format(commence_date);
                                            
                                            return (
                                                <tr key={bet.id}>
                                                    <td>
                                                        <div className='cell-content'>
                                                            <p>{home_team}</p>
                                                            <p>{away_team}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='cell-content'>
                                                            <p>{positive_play_name}</p>
                                                            <p>{negative_play_name}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='cell-content'>
                                                            <p>{positive_play_book}</p>
                                                            <p>{negative_play_book}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='cell-content'>
                                                            <p>{positive_play_price}</p>
                                                            <p>{negative_play_price}</p>
                                                        </div>
                                                    </td>
                                                    <td>{Number(arbitrage_percentage).toFixed(2)}%</td>
                                                    <td>{formatted_commence_time}</td>
                                                </tr>
                                            )
                                        }
                                    )}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div>
                        <p>Loading...</p>
                    </div>
                )}
                <div className='sign-in-cta'>
                    <h2>Sign in to explore all bets:</h2>
                    <button className='cta-button' onClick={() => {navigate("/signin")}}>Sign In</button>    
                </div>
            </div>
        </div>
        )
    }

    if(!subscribed){
        return(
          <div className='arbitrage-content'>
            <Sidebar />
            <div className='main-content'>
                <h3 className='arb-preview-header' > Arbitrage Bets: </h3>
                {previewExists ? (
                    <div className='table-container'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th>Arb Play</th>
                                    <th>Book</th>
                                    <th>Price</th>
                                    <th>Arb %</th>
                                    <th>Commence Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                        previewData.map((bet) => {
                                            let { home_team, away_team } = bet;
                                            let { positive_play_name, negative_play_name } = bet;
                                            let { positive_play_book, negative_play_book } = bet;
                                            let { positive_play_price, negative_play_price } = bet;
                                            let { arbitrage_percentage, commence_time } = bet;

                                            const commence_date = new Date(commence_time)
                                            const options = {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                timeZone: 'America/New_York',
                                                timeZoneName: 'short'
                                            };
                                            
                                            const formatted_commence_time = new Intl.DateTimeFormat('en-US', options).format(commence_date);
                                            
                                            return (
                                                <tr key={bet.id}>
                                                    <td>
                                                        <div className='cell-content'>
                                                            <p>{home_team}</p>
                                                            <p>{away_team}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='cell-content'>
                                                            <p>{positive_play_name}</p>
                                                            <p>{negative_play_name}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='cell-content'>
                                                            <p>{positive_play_book}</p>
                                                            <p>{negative_play_book}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='cell-content'>
                                                            <p>{positive_play_price}</p>
                                                            <p>{negative_play_price}</p>
                                                        </div>
                                                    </td>
                                                    <td>{Number(arbitrage_percentage).toFixed(2)}%</td>
                                                    <td>{formatted_commence_time}</td>
                                                </tr>
                                            )
                                        }
                                    )}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div>
                        <p>Loading...</p>
                    </div>
                )}
                <div className='sign-in-cta'>
                    <h2>Upgrade your plan to explore all bets:</h2>
                    <button className='cta-button' onClick={() => {navigate("/plans")}}>Upgrade Now!</button>    
                </div>
            </div>
          </div>
        )
    }

    return (
        <div className='arbitrage-content'>
            <Sidebar /> 
            <main className='main-content'>
                <div className='title-container'>
                    <h2>Arbitrage Bets</h2>
                    <div className='button-container'>
                        <button onClick={() => {propogateBetData()}}>
                            {dataExists ? ("Update Data") : ("Get Data")}
                        </button>
                        <button onClick={() => {sortByPercent()}}>Sort By %</button>
                    </div>
                </div>
                {dataExists ? (
                    <div className='table-container'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th>Arb Play</th>
                                    <th>Book</th>
                                    <th>Price</th>
                                    <th>Arb %</th>
                                    <th>Commence Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                        betData.map((bet) => {
                                            let { home_team, away_team } = bet;
                                            let { positive_play_name, negative_play_name } = bet;
                                            let { positive_play_book, negative_play_book } = bet;
                                            let { positive_play_price, negative_play_price } = bet;
                                            let { arbitrage_percentage, commence_time } = bet;

                                            const commence_date = new Date(commence_time)
                                            const options = {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                timeZone: 'America/New_York',
                                                timeZoneName: 'short'
                                            };
                                            
                                            const formatted_commence_time = new Intl.DateTimeFormat('en-US', options).format(commence_date);
                                            
                                            return (
                                                <tr key={bet.id}>
                                                    <td>
                                                        <div className='cell-content'>
                                                            <p>{home_team}</p>
                                                            <p>{away_team}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='cell-content'>
                                                            <p>{positive_play_name}</p>
                                                            <p>{negative_play_name}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='cell-content'>
                                                            <p>{positive_play_book}</p>
                                                            <p>{negative_play_book}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='cell-content'>
                                                            <p>{positive_play_price}</p>
                                                            <p>{negative_play_price}</p>
                                                        </div>
                                                    </td>
                                                    <td>{Number(arbitrage_percentage).toFixed(2)}%</td>
                                                    <td>{formatted_commence_time}</td>
                                                </tr>
                                            )
                                        }
                                    )}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div>No Data Loaded Yet</div>
                )}
            </main>
        </div>
    )
}

