import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './components/Sidebar';
import './style-pages/teamschatfeed.css';
import { v4 as uuidv4 } from 'uuid';
import { onAuthStateChanged, getAuth } from 'firebase/auth';

export default function TeamChatFeed() {
    const navigate = useNavigate();
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [teamFeedInfo, setTeamFeedInfo] = useState();
    const [teamMessages, setTeamMessages] = useState([]);
    const [dataExists, setExists] = useState(false);
    const [chatStreamId, setChatStreamId] = useState();
    const [userMessage, setUserMessage] = useState();
    const [authenticated, setAuthentication] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [placeHolder, setPlaceHolder] = useState("Sign In to Post!");
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');
    const [user, setUser] = useState({});
    const [following, setFollowing] = useState(false);

    const handleInputChange = (event) => {
        setUserMessage(event.target.value);
    };

    const getTeamFeedInfo = async () => {
        const queryParams = new URLSearchParams(window.location.search)
        const id = queryParams.get("id")
        setChatStreamId(id);
        
        await axios.post("https://propicks-social-api.com/get-team-feed", {
            team_id: id,
        })
            .then((response) => {
                setTeamFeedInfo(response.data.data);
                if(response.data.data[1].length != 0){
                    setTeamMessages(response.data.data[1])
                }
                setExists(true);
            })
            .catch((err) => {
                console.log({"message": err.message});
            })
    }

    const getUserInfo = useCallback(async (gid) => {
        if (session != null) {
            const userInfo = JSON.parse(session)
            const queryParams = new URLSearchParams(window.location.search)
            const id = queryParams.get("id")

            setUser(userInfo);

            if(userInfo.teams_followed.length != 0){
                if(userInfo.teams_followed.includes(id)){
                    setFollowing(true);
                    console.log("following team")
                }
            }
        } else {
          await axios.post("https://propicks-data-api.com/get-user", {
              gid: gid,
            })
              .then((response) => {
                setUser(response.data.postgres_response);
                sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
              })
              .catch((err) => {
                console.log({ err: err });
              })
        }
    });

    const updateUserInfo = useCallback(async (gid) => {
        await axios.post("https://propicks-data-api.com/get-user", {
            gid: gid,
          })
            .then((response) => {
              setUser(response.data.postgres_response);
              sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
            })
            .catch((err) => {
              console.log({ err: err });
            })
    })

    const postMessage = async () => {
        const date = new Date();
        const uuid = uuidv4();

        const chat = {
            message_id: uuid,
            user_name: user.name,
            user_gid: user.gid,
            message_data: userMessage,
            post_date: date,
            chat_stream_id: chatStreamId,
        }

        const chat_array = [uuid, user.name, user.gid, userMessage, date, chatStreamId, null]

        // Combine these two calls into one, client should not make two api calls in a single method
        await axios.post("https://propicks-social-api.com/post-team-feed-message", {
            chat: chat,
        })
            .then((response) => {
                console.log(response);
                setTeamMessages(teamMessages => [...teamMessages, chat_array])
                setUserMessage('')
                console.log(teamMessages)
            })
            .catch((err) => {
                console.log({"message": err.message});
            })

        await axios.post("https://propicks-data-api.com/add-user-post", {
            "user_gid": user.gid,
            "message_id": uuid,
        })
            .catch((err) => {
                console.log({ "message": err.message });
            })

        forceUpdate();
    }

    const respondToMessage = (user_name) => {
        setUserMessage(`@${user_name} `)
    }

    const likeMessage = (message_id) => {
        console.log(`User: ${user.gid} liked message: ${message_id}`)
    }

    const followTeam = async () => {
        await axios.post("https://propicks-data-api.com/follow-team", {
            team_id: chatStreamId,
            user_gid: user.gid,
        })
            .then((result) => {
                console.log(result);
                updateUserInfo(user.gid);
                setFollowing(true);
                forceUpdate()
            })
            .catch((err) => {
                console.log({'message': err.message})
            })
    }

    const unfollowTeam = async () => {
        await axios.post("https://propicks-data-api.com/unfollow-team", {
            team_id: chatStreamId,
            user_gid: user.gid,
        })
            .then((result) => {
                console.log(result);
                updateUserInfo(user.gid);
                setFollowing(false);
                forceUpdate()
            })
            .catch((err) => {
                console.log({ 'message': err.message });
            })
    }

    const openUserProfile = (user_gid) => {
        const newParams = createSearchParams({
            gid: user_gid
        });
        
        navigate({
            pathname: '/userProfile',
            search: `?${newParams.toString()}`
          });
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user_response) => {
            if(user_response){
              getUserInfo(user_response.uid);
              setAuthentication(true)
              setDisabled(false)
              setPlaceHolder("Enter message...")
            } else {
              console.log("no user :(");
            }
          })
        getTeamFeedInfo();
    }, [])

    if(!dataExists){
        return(
            <div className='teams-feed-content'>
                <Sidebar />
                <div>
                    <p>
                        Loading...
                    </p>
                </div>
            </div>
        )
    }

    return(
        <div className='teams-feed-content'>
            <Sidebar />
            <div className='main-content'>
                <div className='team-top-button-container'>
                    <button style={{height: '30px', width: '100px', marginBottom: '25px', padding: '5px'}} onClick={() => {navigate(-1)}}>{"< Back"}</button>
                    {following ? (
                        <>
                            <button style={{height: '30px', width: '125px', marginBottom: '25px', padding: '5px'}} onClick={() => {unfollowTeam()}}>Unfollow Team</button>
                        </>
                    ) : (
                        <>
                            <button style={{height: '30px', width: '125px', marginBottom: '25px', padding: '5px'}} onClick={() => {followTeam()}}>Follow Team</button>
                        </>
                    )}
                </div>
                <div className='team-feed-title-div' >
                    <div className='team-info-div' >
                        <h2>{teamFeedInfo[0][0]}</h2>
                        <h4 className='next-game-text' >{teamFeedInfo[0][2]}</h4>
                    </div>
                    <div className='team-odds-div'>
                        <p>Team Odds:</p>
                    </div>
                </div>
                <div className='chat-feed'>
                    {
                        teamMessages.map((message) => {
                            return(
                                <div key={message[0]} className='team-feed-message'>
                                    <p><a style={{cursor: 'pointer'}} onClick={() => {openUserProfile(message[2])}}>{message[1]}</a></p>
                                    <p>{message[3]}</p>
                                    <div>
                                        <button style={{height: '30px', width: '60px', marginBottom: '0px', marginTop: '-10px', padding: '0px'}} disabled={disabled} onClick={() => {likeMessage(message[0])}} >Like</button>
                                        <button style={{height: '30px', width: '80px', marginBottom: '0px', marginTop: '-10px', marginLeft: '5px', padding: '0px'}} disabled={disabled} onClick={() => {respondToMessage(message[1])}} >Respond</button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='input-group'>
                    <input className='chat-input-field' type='text' placeholder={placeHolder} disabled={disabled} value={userMessage} onChange={handleInputChange} ></input>
                    <button className='input-button' disabled={disabled} onClick={() => {postMessage()}}> Post </button>
                </div>
            </div>
        </div>
    )
}