import React, { useState, useEffect } from "react";
import Sidebar from "./components/Sidebar";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import "./style-pages/teamschat.css";
import axios from 'axios';
import EnterArrow from './assets/right.svg';

export default function TeamsChat() {
    const navigate = useNavigate();
    const [teamFeeds, setTeamFeeds] = useState({});
    const [dataExists, setExists] = useState(false);

    const getTeamFeeds = async () => {
        await axios.get("https://propicks-social-api.com/get-all-team-feeds")
            .then((response) => {
                console.log(response.data.data);
                setTeamFeeds(response.data.data);
                setExists(true);
            })
            .catch((err) => {
                console.log({"message": err.message})
            })
    }

    const openTeamFeed = (chat_id) => {
        const newParams = createSearchParams({
            id: chat_id
        });
        
        navigate({
            pathname: '/teamchat',
            search: `?${newParams.toString()}`
          });
    }

    useEffect(() => {
        console.log("Getting teams...")
        getTeamFeeds()
    }, [])

    if(!dataExists){
        return(
            <div className="teams-content">
                <Sidebar />
                <div className="main-content">
                    <p>
                        Loading...
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className="teams-content">
            <Sidebar />
            <div className="main-content">
            <h2>All Team Chats: </h2>
            {
                teamFeeds.map((teamFeed) => {
                    return (
                        <div key={teamFeed[1]} className="team-feed-module" onClick={() => {openTeamFeed(teamFeed[5])}}>
                            <div className="game-title-next">
                            <h4 className="team-feed-module-title">
                                {teamFeed[0]}
                            </h4>
                            <p>
                                Next game: {teamFeed[2]}
                            </p>
                            </div>
                            <div className="enter-name">
                                <img src={EnterArrow} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}