import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import { useCallback } from 'react';
import './style-pages/profile.css';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Profile() {
    const navigate = useNavigate();
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');

    const [authenticated, setAuthentication] = useState(false);
    const [user, setUser] = useState({});
    const [userPosts, setUserPosts] = useState([]);
    const [dataExists, setDataExists] = useState(false);
    const [userPostsExist, setUserPostsExist] = useState(false);

    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if(user){
          setAuthentication(true);
          getUserProfile(user.uid)
        } else {
          console.log("no user :(");
        }
      })
    }, [])

    const getUserProfile = async (gid) => {
      try{
        const response = await axios.post("https://propicks-data-api.com/get-user-with-posts", {
          user_gid: gid,
        })

        setUser(response.data.user_info);
        console.log(response.data.user_posts.post_data);

        if(response.data.user_posts.post_data != undefined || response.data.user_posts.post_data != null){
          setUserPosts(response.data.user_posts.post_data)
          setUserPostsExist(true)
        }

        setDataExists(true);
      } catch (err) {
        console.log({ err: err.message })
      }
  };

    const signOutUser = async () => {
      signOut(auth)
        .then(() => {
          sessionStorage.clear();
          navigate("/");
        })
        .catch((err) => {
          console.log({ err: err });
        })
    }

    const likeMessage = () => {

    }

    if(!authenticated){
        return(
          <div className='profile-content'>
            <Sidebar />
            <div className='main-content'>
              <div className="login-cta-div" >
                <h3>Don't have an account? Create one now!</h3>
                <button>Sign In</button>
              </div>
            </div>
          </div>
        )
    }

    if(!dataExists) {
      return(
        <div className='profile-content'>
          <Sidebar />
          <div className='main-content'>
            <p>Loading...</p>
          </div>
        </div>
      )
    }

    if(authenticated && dataExists){
        return (
            <div className='profile-content'>
                <Sidebar />
                <main className='main-content'>
                    <div className='profile-header-div'>
                      <h2> {user.name} </h2>
                      <div className='counter-div'>
                        <h3> {user.user_posts.length} </h3>
                        <p> Posts </p>
                      </div>
                      <div className='counter-div'>
                        <h3> {user.teams_followed.length} </h3>
                        <p> Teams </p>
                      </div>
                    </div>
                    <div className='profile-mid-section'>
                      <div className='profile-sidebar'>
                        <div className='profile-sidebar-info'>
                          <p> Bio: </p>
                          <p> Email: {user.email}</p>
                          <p> Paid: {user.paid ? "Free" : "Paid"}</p>
                          <p> Tier: {user.tier} </p>
                        </div>
                        <button classname="option-button" onClick={() => {navigate("/plans")}} > Plans </button>
                        <button classname="option-button" onClick={() => {navigate("/settings")}} > Options </button>
                        <button classname="option-button" onClick={() => {signOutUser()}} > Log Out </button>
                      </div>
                      <div className='profile-chat-stream'>
                        {userPostsExist ? (
                          <>
                            {userPosts.map((message) => {
                              return(
                                  <div key={message[0]} className='profile-user-feed-message'>
                                      <p>{message[1]}</p>
                                      <p>{message[3]}</p>
                                      <div>
                                          <button style={{height: '30px', width: '60px', marginBottom: '0px', marginTop: '-10px', padding: '0px'}} onClick={() => {likeMessage(message[0])}} >Like</button>
                                      </div>
                                  </div>
                                )
                              })
                            } 
                          </>
                        ) : (
                          <>
                            <p> Loading your posts... </p>
                          </>
                        )}
                      </div>
                    </div>
                </main> 
            </div>
        )    
    }
    
}
