import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import { useCallback } from 'react';
import './style-pages/profile.css';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Profile() {
    const navigate = useNavigate();
    const auth = getAuth();
    const session = sessionStorage.getItem('accountData');

    const [authenticated, setAuthentication] = useState(false);
    const [user, setUser] = useState({});

    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if(user){
          setAuthentication(true);
          getUserInfo(user.uid);
        } else {
          console.log("no user :(");
        }
      })
    }, [])

    const getUserInfo = useCallback(async (gid) => {
        if (session != undefined) {
          setUser(JSON.parse(session));
        } else {
          console.log("session was null")
          await axios.post("https://propicks-data-api.com/get-user", {
              gid: gid,
            })
              .then((response) => {
                setUser(response.data.postgres_response);
                sessionStorage.setItem('accountData', JSON.stringify(response.data.postgres_response));
              })
              .catch((err) => {
                console.log({ err: err });
              })
        }
    });

    const signOutUser = async () => {
      signOut(auth)
        .then(() => {
          sessionStorage.clear();
          navigate("/");
        })
        .catch((err) => {
          console.log({ err: err });
        })
    }

    if(!authenticated){
        return(
          <div className='profile-content'>
            <Sidebar />
            <div className='main-content'>
              <button>Sign In</button>
            </div>
          </div>
        )
    }

    if(authenticated){
        return (
            <div className='profile-content'>
                <Sidebar />
                <main className='main-content'>
                    <div className='profile-header-div'>
                      <h2> {user.name} </h2>
                      <div className='counter-div'>
                        <h3> {user.user_posts.length} </h3>
                        <p> Posts </p>
                      </div>
                      <div className='counter-div'>
                        <h3> {user.teams_followed.length} </h3>
                        <p> Teams </p>
                      </div>
                    </div>
                    <p> Email: {user.email}</p>
                    <p> Paid: {user.paid ? "Free" : "Paid"}</p>
                    <p> Tier: {user.tier} </p>
                    <div className='button-container'>
                        <button onClick={() => {signOutUser()}} > Log Out </button>
                    </div>
                </main> 
            </div>
        )    
    }
    
}
