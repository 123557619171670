import React, { useCallback, useEffect, useState } from 'react';
import { getAuth, signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import './style-pages/signup.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from './assets/PP_Logo.png';
import { googleProvider } from '../firebase';

export default function SignUp({ sign_obj }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [isSignUp, setIsSignUp] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const auth = getAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if(sign_obj){
      if(sign_obj.signin == true){
        setIsSignUp(false);
      }
    }
  },[])

  const createUserCallback = useCallback(async (gid) => {

    setLoading(true);
    let currentDate = new Date();
    let currentUTCTimestamp = currentDate.toUTCString();
    let response;

    const user_data = {
      email: email, 
      name: name,
      beta: true,
      paid: false,
      tier: 0,
      created: currentUTCTimestamp,
      verified: false,
      gid: gid,
    }

    try{
      response = await axios.post("https://propicks-data-api.com/create-user", user_data)
      setLoading(false);

      if(response.status == 200){
        navigate("/");
      } else {
        console.log("An err occured...\n")
      }
    } catch (err) {
      console.log({ err: err.message })
    }
  })

  const handleEmailLogin = async () => {
    setError(false);
    try {
      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password)
          .then((newUser) => {
            createUserCallback(newUser.user.uid);
          })
          .catch((err) => {
            console.log({ err: err })
            console.log("ERR creating new google user account")
            setError(true)
          })
      } else {
        await signInWithEmailAndPassword(auth, email, password)
          .then(() => {
            navigate("/")
          })
          .catch((err) => {
            console.log({ err: err });
            setError(true)
          })
      }
    } catch (error) {
      console.error('Error with email login/signup', error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      console.error('Error with Google login', error);
    }
  };

  return (
    <div className="sign-in-page" >
      {!loading ? 
        (<><div className="sign-in-container" >
        <div className="sign-in-header" >{isSignUp ? (
          <>
            <img src={logo} height="80px" className='propicks-login-logo'/>
            <h2> Sign Up </h2>
          </>
        ) : (
          <>
            <img src={logo} height="80px" className='propicks-login-logo'/>
            <h2> Log In </h2>
          </>
        )}</div>
        {isSignUp && (
          <input className='signin-input'
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        )}
        <input className='signin-input'
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input className='signin-input'
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        { !error ? (<></>) : (
          <>
            <p className='errorMsg'>There was an Error!</p>
          </>
        )}
        <button className='signin-button' onClick={handleEmailLogin}>
          {isSignUp ? 'Sign Up' : 'Sign In'}
        </button>
        <p>
          {isSignUp
            ? 'Already have an account? '
            : "Don't have an account? "}
          <span onClick={() => setIsSignUp(!isSignUp)}>
            {isSignUp ? 'Sign In' : 'Sign Up'}
          </span>
        </p>
        <div className="google-sign-in">
          <button className='signin-button' onClick={handleGoogleLogin}>Sign In with Google</button>
        </div>
      </div>
      </>) : 
      (<>
        <div>
          <h2>Loading...</h2>
        </div>
      </>)}
      
    </div>
  );
};

