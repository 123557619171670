// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#personalCheckoutDiv {
    font-family: 'Inter';
    height: 800;
}`, "",{"version":3,"sources":["webpack://./src/user-flow-pages/style-pages/paymentpage.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,WAAW;AACf","sourcesContent":["#personalCheckoutDiv {\n    font-family: 'Inter';\n    height: 800;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
