import React, { useState } from 'react';
import './style-pages/userfunnel4.css';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';

import default_pfp from '../assets/pfp.jpg';

// Here is the plan:
// Upload a photo to the form, then on hitting continue we will run the upload. From the upload,
// we will pass the link back to the base container that will allow it to be saved in the user
// database row.

export default function UserFunnel4({ onNext }) {
    const [image, setImage] = useState(default_pfp);
    const [defaultPfp, setDefaultPfp] = useState(true);
    const [viewImage, setViewImage] = useState(default_pfp)
    const [progress, setProgress] = useState(0);
    const [username, setUsername] = useState("");
    const [bio, setBio] = useState("");

    const standard = "https://firebasestorage.googleapis.com/v0/b/propicksio.firebasestorage.app/o/defaultProfilePicture%2Fuser_pfp.jpg?alt=media&token=71272afc-3c7b-4d62-8c0d-2c2357be4207";

    const auth = getAuth();
    const storage = getStorage();

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            const uploaded_img = URL.createObjectURL(e.target.files[0])

            setImage(e.target.files[0]);
            setViewImage(uploaded_img);
            setDefaultPfp(false);
        }
    };

    const uploadPfp = () => {
        if(!image)return;

        const user = getAuth().currentUser;
        if (!user) {
            alert("Please log in first");
            return;
        }

        const storageRef = ref(storage, `profilePictures/${user.uid}`);
        const uploadTask = uploadBytesResumable(storageRef, image);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
            },
            (error) => {
                console.error("Upload failed", error);
            },
            async () => {
                const url = await getDownloadURL(uploadTask.snapshot.ref);
                
                if(defaultPfp){
                    onNext({ pfp_url: standard, username: username, bio: bio });
                } else {
                    onNext({ pfp_url: url, username: username, bio: bio });
                }
            }
        )
    }

    const updateName = (e) => {
        setUsername(e.target.value);
    }

    const updateBio = (e) => {
        setBio(e.target.value);
    }

    return(
        <div className='account-create-content' >
            <p className='create-profile-title'>Create Profile</p>
            <div className="image-input">
                <img src={viewImage} className='pfp-display' height="84" width="84" />
                <input type="file" accept="image/*" onChange={handleFileChange} />
            </div>
            <div className='create-username-container' >
                <p>Username</p>
                <textarea className='create-username' placeholder='Enter Username...' onChange={(e) => {updateName(e)}}/>
            </div>
            <div className='create-bio-container'>
                <p>Bio</p>
                <textarea className='create-bio' placeholder="Tell us about yourself!" onChange={(e) => {updateBio(e)}}/>
            </div>
            <div className='create-continue-button' onClick={uploadPfp}>
                <p>Continue</p>
            </div>
            <progress value={progress} max="100" className='create-profile-progress' />
        </div>
    )
}