import React from 'react';
import EnterArrow from './assets/right.svg';
import './style-pages/sportschat.css';
import { useNavigate, createSearchParams } from 'react-router-dom';
import Sidebar from './components/Sidebar';

export default function SportsChat() {
    const navigate = useNavigate();

    const sports = [{
        key: 'nfl',
        title: 'NFL Football',
    }, {
        key: 'nba',
        title: 'NBA Basketball',
    }, {
        key: 'mlb',
        title: "Major League Baseball",
    }, {
        key: 'ncaab',
        title: 'College Basketball',
    }, {
        key: 'ncaaf',
        title: 'College Football',
    }]

    const openSportFeed = (sport_id) => {
        const newParams = createSearchParams({
            id: sport_id
        });
        
        navigate({
            pathname: '/teams',
            search: `?${newParams.toString()}`
          });
    }

    return(
        <div className="sports-content">
            <Sidebar />
            <div className="main-content">
            <h2>All Sports: </h2>
            
            </div>
        </div>
    )
}