import React from 'react';
import './style-pages/terms.css';
import TopBar from './components/TopBar';

export default function Terms() {
    return (
        <div className='terms-content'>
            <TopBar />
        </div>
    )
}