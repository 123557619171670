// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notifications-content {
    width: 100%;
    height: 100vh;
    background-color: var(--brand-shade-500);
}

.notifications-page-container {
    height: 100%;
    width: 50%;
    padding: 36px 25% 24px 25%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: left;
}

.notifications-title {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 24px;
    width: 100%;
    color: var(--light-grey-100);
    border-bottom: 1px solid var(--brand-shade-100);
}`, "",{"version":3,"sources":["webpack://./src/dash-pages/style-pages/notifications.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,wCAAwC;AAC5C;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,0BAA0B;IAC1B,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gCAAgC;IAChC,iBAAiB;IACjB,WAAW;IACX,4BAA4B;IAC5B,+CAA+C;AACnD","sourcesContent":[".notifications-content {\n    width: 100%;\n    height: 100vh;\n    background-color: var(--brand-shade-500);\n}\n\n.notifications-page-container {\n    height: 100%;\n    width: 50%;\n    padding: 36px 25% 24px 25%;\n    display: flex;\n    flex-direction: column;\n    justify-content: start;\n    align-items: left;\n}\n\n.notifications-title {\n    font-size: 20px;\n    font-weight: 600;\n    font-family: 'Inter', sans-serif;\n    line-height: 24px;\n    width: 100%;\n    color: var(--light-grey-100);\n    border-bottom: 1px solid var(--brand-shade-100);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
