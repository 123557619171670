import React, { useState } from 'react';
import './style-pages/userfunnel2.css';

export default function UserFunnel2({ onNext }) {
    const [search, setSearch] = useState("");
    const [selectedState, setSelectedState] = useState("");

    const states = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", 
        "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", 
        "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", 
        "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", 
        "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", 
        "New Hampshire", "New Jersey", "New Mexico", "New York", 
        "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", 
        "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", 
        "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", 
        "West Virginia", "Wisconsin", "Wyoming"
    ];

    const filteredStates = states.filter(state =>
        state.toLowerCase().includes(search.toLowerCase())
    );

    return(
        <div className='state-content' >
            <p className='state-title' >Select Your State</p>
            <div className='state-select-container' >
                <div className='state-search-container' >
                    <textarea className='state-search' placeholder='Search Your State' onChange={(e) => setSearch(e.target.value)} />
                </div>
                <div className='states-list' >
                    {
                        filteredStates.map((state) => {
                            return(
                                <div className={`state-button ${selectedState === state ? "selected" : ""}`} onClick={() => {setSelectedState(state)}}>
                                    <p>{state}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='state-continue-button' onClick={() => onNext({ state: selectedState })} >
                <p>Continue</p>
            </div>
        </div>
    )
}