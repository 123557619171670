import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';

export default function ProfilePicture() {
    const navigate = useNavigate();

    return(
        <div className='profile-content'>
            <Sidebar/>
            <div className='main-content'>
                <h3>Change Profile Picture</h3>
                <input type='file' />
                <button style={{width: 150, }}>Upload</button>
            </div>
        </div>
    )
}