import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Process() {
    const { isAuthenticated, isLoading, user, error} = useAuth0();
    const navigate = useNavigate();

    const [paid, setPaid] = useState();
    const [tier, setTier] = useState();
    const [ready, setReady] = useState(false);

    // This page is mostly done, just clean it up and then move on to the pricing page and how we figure out that the user has paid...

    const get_user_subscription_info = async () => {
        const result = axios.post("https://propicks-data-api/get-user-sub", {
            user_id: user.sub.split("|")[1],
        })

        const { paid, tier } = result;

        setPaid(paid);
        setTier(tier);
    }

    const navigate_user = () => {
        if (!paid && tier == 0) {
            navigate("/pricing");
        } else if (paid && tier == 1) {
            navigate("/dashboard");
        } else if (paid && tier == 0) {
            return(
                <div>
                    You have paid but no subscription is associated with you account, contact customer service at contact@propicks.io
                </div>
            )
        } else {
            navigate("/error");
        }
    }

    /*
    useEffect(() => {
        console.log(user.sub);

        if(!isLoading){
            console.log(user.sub);
            get_user_subscription_info();
            navigate_user();
        }
    }, [user?.sub])
    */

    useEffect(() => {
        const getUserMetadata = async (id) => {
            const domain = "https://propicks-data-api/get-user-sub";
            const data = {
                user_id: id,
            }
            try {

                const response = await axios.post(domain, data, {
                    headers: {
                        'Content-Type': "application/json",
                    }
                });

                console.log(response)
                const { user_paid, user_tier } = response.data;

                setPaid(user_paid);
                setTier(user_tier);
                setReady(true);
            } catch (e) {
                console.log({ 
                    err: e,
                    msg: e.message,
                });
            }
        };

        if(isAuthenticated){
            const id = user.sub.split("|")[1];
            console.log(id);
            getUserMetadata(id)
        }

        
    }, [user?.sub]);

    if(isLoading){
        return(<div>Loading...</div>)
    }

    
    if(isAuthenticated && ready) {
        navigate_user();
    }
    

    if(error){
        return(
            <div>
                <p>There was an error... try signing in again.</p>
                <p>If the error persists, contact us at contact@propicks.io</p>
            </div>
        )
    }

    return(
        <div>
            Loading...
        </div>
    )
}
